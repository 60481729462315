import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { parseDateObj, uuidv4 } from '../../../common/util'
import { GrayCustomCard, RowGapWrapper } from '../../../common/Theme/style'
import { CardHeader } from '../../components/contractRecordDetail/CardHeader'
import { HeaderGroup, Spacer } from '../../../common/components/PageHeaderStyles'
import { getRecordFormStatus } from '../../cacheAndNavigation/contractRecordDetail/cache'
import { useDepositForm } from '../../hooks/contractRecord/contractRecordDetail/useDepositForm'
import { navigateToContractRecordDetail } from '../../cacheAndNavigation/contractRecordDetail/navigation'
import { useSavingContractRecordDetailForm } from '../../hooks/contractRecord/contractRecordDetail/useSavingContractRecordDetailForm'

import styled from 'styled-components'
import _ from 'lodash'
import { Button, Table, Form, Modal } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
dayjs.locale('ko')

const StyledTable = styled(Table)`
  display: flex;
flex-direction: column;
  width: 100%;
  .ant-table-wrapper .ant-table-cell-fix-left, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table-cell-fix-right {
    z-index: 1;
  }
  .ant-table-row {
    cursor: pointer;
  }
`

export const DepositForm = ({ contractRecordData, editMode, setEditMode, currentUser, formEditable, refetch, form }) => {
  const { permissions } = useOutletContext()

  const paymentRecords = _.get(contractRecordData, 'paymentRecords', [])
  useEffect(() => {
    setDataSource(contractRecordData)
    /* 입금 내역 */
    const depositsArray = _.flatMap(
      _.filter(paymentRecords, (record) => record.deposits.length > 0),
      (record) =>
        record.deposits.map((deposit) => ({
          ...deposit,
          editDepositRecordId: record.id,
          periodicId: record.periodicId,
          dueAmount: record.dueAmount
        }))
    )
    form.setFieldsValue({ depositRecordList: depositsArray })
    setDepositDataSource(depositsArray)
  }, [contractRecordData])

  const [dataSource, setDataSource] = useState() // 계약 정보
  const [contractDataSource, setContractDataSource] = useState([]) // 계약 목록
  const [extraContractDataSource, setExtraContractDataSource] = useState([]) // 추가 계약 목록
  const [depositDataSource, setDepositDataSource] = useState([]) // 입금 내역
  const [reasonDataSource, setReasonDataSource] = useState([])
  const [newContractIds, setNewContractIds] = useState([])
  const [deletedContractIds, setDeletedContractIds] = useState([])
  const [newReasonIds, setNewReasonIds] = useState([])
  const [removedReasonIds, setRemovedReasonIds] = useState([])
  const [newDepositIds, setNewDepositIds] = useState([])
  const [removedDepositIds, setRemovedDepositIds] = useState([])
  const navigate = useNavigate()

  // const [form] = Form.useForm() // 수정중인데 나갈 경우, 경고창
  const formStatus = getRecordFormStatus()

  const {
    onEdit,
    onCancel,
    onSave,
    readOnly
  } = useSavingContractRecordDetailForm(
    dataSource,
    formStatus,
    newContractIds,
    deletedContractIds,
    refetch,
    setNewContractIds,
    setDeletedContractIds,
    newReasonIds,
    setNewReasonIds,
    removedReasonIds,
    setRemovedReasonIds,
    newDepositIds,
    setNewDepositIds,
    removedDepositIds,
    setRemovedDepositIds,
    null, // pageDelete
    setEditMode,
    setContractDataSource,
    contractDataSource
  )

  // 추심목록 및 입금 내역 삭제
  const handleDelete = (key, type) => {
    if (type === 'commentList') {
      const newData = _.reject(reasonDataSource, { id: key })

      setReasonDataSource(newData)
      form.setFieldsValue({ commentList: newData })

      /* 추가 계약 조건 */
      if (_.includes(newReasonIds, key)) {
        setNewReasonIds((current) => _.without(current, key))
      } else {
        setRemovedReasonIds((current) => [...current, key])
      }
    } else { // depositRecordList
      const newData = _.reject(depositDataSource, { id: key })

      setDepositDataSource(newData)
      form.setFieldsValue({ depositRecordList: newData })

      if (_.includes(newDepositIds, key)) {
        setNewDepositIds((current) => _.without(current, key))
      } else {
        setRemovedDepositIds((current) => [...current, key])
      }
    }
  }

  const {
    formItems
  } = useDepositForm(
    readOnly,
    contractDataSource,
    reasonDataSource,
    extraContractDataSource,
    setExtraContractDataSource,
    depositDataSource,
    setDepositDataSource,
    handleDelete,
    currentUser,
    _.get(contractRecordData, 'paymentRecords', []),
    form
  )
  const checkOverdue = () => {
    const uniquePeriodicIds = _.uniqBy(depositDataSource, 'periodicId').map(record => record.periodicId)
    console.log('Unique periodicIds:', uniquePeriodicIds)

    const sumsByPeriodicId = uniquePeriodicIds.reduce((acc, periodicId) => {
      const total = _.sumBy(
        _.filter(depositDataSource, { periodicId }),
        'receivedAmount'
      )
      acc[periodicId] = total
      return acc
    }, {})
    const isOverdue = _.some(paymentRecords, (record) => {
      const totalReceivedAmount = sumsByPeriodicId[record.periodicId] || 0
      return totalReceivedAmount > record.dueAmount
    })
    return isOverdue
  }

  const handleSave = (type) => {
    // 입금 내역의 입금 금액이 계약 금액의 총 금액 초과 여부 확인
    const overdue = checkOverdue()
    if (!overdue) {
      onSave(form, contractRecordData.id, type)
      navigateToContractRecordDetail(navigate, contractRecordData, null, 'view')
    } else {
      // warning modal
      Modal.warning({
        title: '입금 금액 초과 경고',
        content: (
          <>
            입금 금액이 초과된 항목이 있습니다. <br />확인 후 다시 시도해 주세요.
          </>
        ),
        okText: '확인',
        onOk: () => {
          // You can define any additional actions on Ok if needed
          console.log('Warning acknowledged.');
        }
      })
    }
  }
  const handleAddDeposit = (dataIndex, depositType, category, receivedAmount = 0, type = null, expenseProofMethod = null, condition = '') => {
    const newId = uuidv4()
    const newData = {
      key: newId,
      id: newId,
      category,
      expenseProofMethod,
      received: parseDateObj(dayjs(new Date())),
      receivedAmount,
      type,
      createdBy: currentUser?.name,
      condition
    }
    setDepositDataSource([...depositDataSource, newData])
    form.setFieldsValue({ depositRecordList: [...depositDataSource, newData] })
    setNewDepositIds([...newDepositIds, newId])
  }

  return (
    <>
      <Form
        form={form}
        onValuesChange={(changedValues, allValues) => {
          console.log('allValues', allValues)
        }}
        // initialValues={{ contract: contractDataSource, extraContract: extraContractDataSource, commentList: reasonDataSource, depositRecordList: depositDataSource }}
      >
        {formItems.map(({ key, cardTitle, value, scrollHeight, columns, component, dataIndex }) => {
          return (
            <GrayCustomCard key={key} $readOnly={readOnly}>
              <RowGapWrapper $gap={20}>
                <CardHeader type='button' dataIndex={dataIndex} title={cardTitle} value={value} readOnly={readOnly} />
                {!readOnly && <Button onClick={() => handleAddDeposit()}>입금 내역 추가</Button>}
                <Spacer />
                {formEditable
                  ? (
                      readOnly
                        ? (
                          <HeaderGroup>
                            {(_.includes(permissions, 'UPDATE_PROJECT_DATA')) && ( // 수정: permissions
                              <>
                                <Button
                                  color='primary'
                                  variant='outlined'
                                  icon={<EditOutlined />}
                                  style={{ display: 'flex', alignItems: 'center' }}
                                  onClick={() => onEdit()}
                                  disabled={editMode}
                                >
                                  수정하기
                                </Button>
                              </>
                            )}
                          </HeaderGroup>)
                        : (
                          <HeaderGroup>
                            {_.includes(permissions, 'UPDATE_PROJECT_DATA') && (
                              <>
                                <Button onClick={() => onCancel(form, 'depositRecordList')}>
                                  취소하기
                                </Button>
                                <Button
                                  type='primary'
                                  onClick={async () => {
                                    try {
                                      await form.validateFields()
                                      handleSave('depositRecordList')
                                    } catch (errorInfo) {
                                      console.error('Validation Failed:', errorInfo)
                                    }
                                  }}
                                >
                                  저장하기
                                </Button>
                              </>)}
                          </HeaderGroup>))
                  : null}
              </RowGapWrapper>
              <StyledTable
                key={key}
                columns={columns}
                dataSource={key === 'commentList' ? reasonDataSource : depositDataSource}
                pagination={false}
                scroll={{
                  y: scrollHeight
                }}
              />
            </GrayCustomCard>
          )
        })}
      </Form>
    </>
  )
}
