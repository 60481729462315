import React from 'react'
import { allPaymentCategoryOptions } from '../../../config'
import { MoneyContainer, RowGapWrapper } from '../../../../common/Theme/style'
import ProgressBar from '../../../components/contractRecordDetail/ProgressBar'
import { CustomDatePicker } from '../../../components/common/CustomDatePicker'
import { getLabelByValue, roundDecimal } from '../../../components/common/CommonFunction'
import CompletePaymentButton from '../../../components/contractRecordDetail/CompletePaymentButton'

import _ from 'lodash'
import { Button, Form, InputNumber } from 'antd'
import COLORS from '../../../../common/Theme/colors'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
dayjs.locale('ko')

export const useContractPaymentColumns = (form, contractType, readOnly, onDeleteContractPayment = () => {}, editMode, handleSave = () => {}, refetch, pageStatus) => {
  const contractColumns = [
    {
      title: '금액 종류',
      dataIndex: 'category',
      key: 'category',
      width: 90,
      render: (category, _, index) => {
        const currentLabel = getLabelByValue(allPaymentCategoryOptions, category)
        return (
          <div bordered={false} color='processing' style={{ color: `${COLORS.primaryColor}` }}>
            {currentLabel + (index + 1)}
          </div>
        )
      }
    },
    {
      title: '총 금액',
      dataIndex: 'dueAmount',
      key: 'dueAmount',
      width: 200,
      render: (dueAmount, record, index) => {
        return (
          !readOnly
            ? (
              <Form.Item
                name={[contractType, record.periodicId, 'dueAmount']}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value <= 0) {
                        return Promise.reject(new Error('0보다 큰 숫자를 입력해주세요.'))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <InputNumber
                  changeOnWheel
                  defaultValue={dueAmount === 0 ? 0 : dueAmount}
                  placeholder='0'
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  addonAfter='원'
                  min={0}
                />
              </Form.Item>)
            : (
              <MoneyContainer value={dueAmount === null ? 0 : dueAmount} />)
        )
      }
    },
    {
      title: '입금 기한',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: 150,
      render: (dueDate, record, index) => {
        return (
          !readOnly
            ? (
              <Form.Item
                name={[contractType, record.periodicId, 'dueDate']}
                rules={[{ required: true, message: '날짜를 선택해주세요.' }]}
              >
                <CustomDatePicker
                  date={dueDate?.length > 0 ? dayjs(dueDate) : null}
                  onChange={(date) => {
                    const newDateTime = date ? date.format('YYYY-MM-DD') : null
                    form.setFieldValue([contractType, record.periodicId, 'dueDate'], newDateTime)
                  }}
                />
              </Form.Item>)
            : (
              <div>{dueDate}</div>)
        )
      }
    },
    {
      title: '총 입금 금액',
      dataIndex: 'totalReceivedAmount',
      key: 'totalReceivedAmount',
      width: 150,
      render: (totalReceivedAmount, record, index) => {
        return (
          <MoneyContainer value={roundDecimal(totalReceivedAmount)} />
        )
      }
    },
    {
      title: '',
      dataIndex: 'prgressBar',
      key: 'prgressBar',
      width: 200,
      render: (_, record, index) => {
        const remain = record.dueAmount - record.totalReceivedAmount
        const percent = (record.totalReceivedAmount / record.dueAmount) * 100
        return (
          <ProgressBar
            percent={roundDecimal(percent)}
            remain={remain}
          />
        )
      }
    },
    {
      title: '',
      dataIndex: 'buttons',
      key: 'buttons',
      render: (buttons, record, index) => {
        if (!pageStatus) {
          return null
        } else if (readOnly) {
          const remain = record.totalDueAmount - record.totalReceivedAmount
          const payments = _.get(record, 'payments', {})
          return (
            <RowGapWrapper>
              <CompletePaymentButton
                index={index}
                editMode={editMode}
                payments={payments}
                buttonKey={record.id}
                periodicId={record.periodicId}
                remain={remain}
                form={form}
                refetch={refetch}
              />
            </RowGapWrapper>
          )
        } else {
          return (
            <Button danger onClick={() => onDeleteContractPayment(form, contractType, record.periodicId)}>삭제</Button>
          )
        }
      }
    }
  ]

  return {
    contractColumns
  }
}
