import _ from 'lodash'
import { useCustomTable } from '../../common/useCustomTable'
import { useNavigate, useParams } from 'react-router-dom'
import { navigateToCallRecord } from '../../../cacheAndNavigation/callRecord/navigation'
import { getRecordContentGroupMode, getRecordContentViewMode } from '../../../cacheAndNavigation/callRecord/cache'
import { useRecordFilterSearchParams } from '../../filter/callRecord/useRecordFilterSearchParams'

export const useRecordTable = () => {
  const {
    meta: {
      filter: filterMeta
    },
    searchParams,
    getUpdatedSearchParams
  } = useRecordFilterSearchParams()

  const viewMode = getRecordContentViewMode()
  const groupMode = getRecordContentGroupMode()
  const searchString = searchParams.toString()
  const navigate = useNavigate()
  const tableProps = useCustomTable()
  const { recordStatus } = useParams()

  const {
    selectedRowKeys,
    onChangeSelectedRowKeys
  } = tableProps
  const disabledTableActionByKey = {
    copyRecord: _.isEmpty(selectedRowKeys),
    delete: _.isEmpty(selectedRowKeys),
    save: _.isEmpty(selectedRowKeys)
  }
  const hiddenTableActionByKey = {
    stat: false,
    excelDownload: false,
    copyRecord: false,
    delete: false
  }
  const onChangeViewMode = v => {
    navigateToCallRecord(navigate, recordStatus, searchString, v)
  }
  const onChangeGroupMode = v => {
    navigateToCallRecord(navigate, recordStatus, searchString, 'group', v)
  }
  const onSelectFilter = (key, value) => {
    // user or case
    const newSearchParams = getUpdatedSearchParams('filter', key, value, searchParams)
    navigateToCallRecord(navigate, recordStatus, newSearchParams.toString(), viewMode)
  }
  return {
    viewMode,
    groupMode,
    disabledTableActionByKey,
    hiddenTableActionByKey,
    recordStatus,
    onChangeViewMode,
    onChangeGroupMode,
    onSelectFilter,
    filterMeta,
    onChangeSelectedRowKeys,
    ...tableProps
  }
}
