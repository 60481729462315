import { Modal } from 'antd'
import styled from 'styled-components'
import { useState, useRef, useEffect } from 'react'
import TextArea from 'antd/es/input/TextArea'
import _, { debounce } from 'lodash'

const StyledModal = styled(Modal)`
  height: 700px;
`

export const ContentModal = ({
  pageType,
  visible,
  onOk,
  onCancel,
  setContentInput,
  form,
  editingRowKey,
  callRecordDataLoading
}) => {
  const [localContent, setLocalContent] = useState()
  useEffect(() => {
    if (pageType === 'call') {
      if (!callRecordDataLoading) {
        const contentData = form.getFieldValue('call')
        const result = _.get(contentData, `[${editingRowKey}].content`, null)
        setLocalContent(result)
      }
    } else {
      const contentData = form.getFieldValue('scratchPad')
      const result = _.get(contentData, `[${editingRowKey}].content`, null)
      setLocalContent(result)
    }
  }, [editingRowKey, visible])
  const textAreaRef = useRef(null)
  const debouncedSetContentInput = useRef(
    debounce((value) => {
      setContentInput(value)
    }, 300)
  ).current

  const handleContentChange = (e) => {
    const value = e.target.value
    textAreaRef.current = value
    setLocalContent(value)
    debouncedSetContentInput(value)
  }

  const handleOk = () => {
    onOk(localContent)
  }

  const handleCancel = () => {
    onCancel()
    setLocalContent('')
  }

  return (
    <StyledModal
      title='내용 수정'
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      height={800}
      okText='확인'
      cancelText='취소'
    >
      <TextArea
        value={localContent}
        style={{ height: '500px' }}
        onChange={handleContentChange}
      />
    </StyledModal>
  )
}
