import _ from 'lodash'
import { useQuery } from '@apollo/client'

import { getProjectTypeDevList } from '../../../call/apollo/callRecordQueries'

export const useProjectTypes = (skip, searchQuery) => {
  const { data: _projectTypes, projectTypesLoading } = useQuery(getProjectTypeDevList, {
    fetchPolicy: 'network-only',
    variables: {
      ...searchQuery
        ? {
            filter: {
              searchQuery
            }
          }
        : {}
    },
    skip
  })

  const projectTypes = _.map(_.get(_projectTypes, ['GetProjectTypeDevList'], []))
  return {
    projectTypes,
    projectTypesLoading
  }
}
