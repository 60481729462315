import { useEffect, useRef, useState } from 'react'
import { useDepartmentList } from '../../common/filter/useDepartmentList'
import UserIcon from '../../../../common/components/UserIcon'
import { OnOffTextColor } from '../../../components/common/OnOffTextColor'
import { useCallRecordSaveEdit } from '../../callRecord/useCallRecordSaveEdit'
import { CustomDatePicker } from '../../../components/common/CustomDatePicker'
import { EditSaveRowButton } from '../../../components/customTable/EditSaveRowButton'
import { ContractStateColor } from '../../../components/customTable/ContractStateColor'
import DepartmentTag from '../../../../common/components/DepartmentTag'
import { useCategories } from '../../callRecord/useCategories'
import { CustomTimePicker } from '../../../components/common/CustomTimePicker'
import { MoneyContainer } from '../../../../common/Theme/style'
import { calculateColumnWidth, convertToDayjs, parseDateObj, parseTimeObj } from '../../../../common/util'
import { findMatchingProjectType, findUsersUsingIds, formatArrayToComma, getLabelByValue, parseMetaToSelectOptions } from '../../../components/common/CommonFunction'

import _ from 'lodash'
import styled from 'styled-components'
import TextArea from 'antd/es/input/TextArea'
import { Button, Form, Input, InputNumber, Modal, Popover, Select, Space } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { useProjectTypes } from '../../../../timeCharge/hooks/apollo/useProjectTypes'
dayjs.locale('ko')

const EllipsisContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`
const StyledButton = styled(Button)`
  :where(.css-dev-only-do-not-override-4oxdqr).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #000;
    border-color:#000;
  }
`
const StyledModal = styled(Modal)`
  height: 700px;
`

export const useRecordViewerColumns = (selectedRowKeys, dataSource, setDataSource, form, refetch, callRecordsData, handleNavigateContractState, currentForm, setCurrentForm, contractRecordsData, users, projectSubTypes, callResercationStatus, setProjectTypeModalOpen, setEditingRowKey, setContentModalOpen, projectTypes, projectTypesLoading, projectTypeSelection) => {
  const currentFormData = form.getFieldValue('call')
  const [editingKeys, setEditingKeys] = useState([])
  const isCheckboxSelected = (record) => selectedRowKeys.includes(record.id)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [advancedSearch, setAdvancedSearch] = useState(false)
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([])
  const [projectTypeList, setProjectTypeList] = useState([])
  const textAreaRef = useRef(null)

  const {
    onEditCallRecord
  } = useCallRecordSaveEdit(form, null, refetch)

  const isEditing = (record) => editingKeys.includes(record.id)

  const showContentModal = (index) => {
    setContentModalOpen(true)
    setEditingRowKey(index)
  }
  const projectTypeShowModal = (index) => {
    setProjectTypeModalOpen(true)
    setEditingRowKey(index)
  }
  const handleContentChange = (e) => {
    textAreaRef.current = e.target.value
  }
  const handleOk = (key, index) => {
    form.setFieldValue(['call', index, 'content'], textAreaRef.current)

    // form.setFieldsValue({
    //   call: {
    //     ...form.getFieldValue('call'),
    //     [key]: {
    //       content: textAreaRef.current
    //     }
    //   }
    // })
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleAdvancedSearch = (e) => {
    // e.preventDefault()
    setAdvancedSearch(true)
    onDepartmentListComplete(departments)
  }

  const formatGender = (value) => {
    if (value !== null) {
      return value === 'MALE' ? '남성' : '여성'
    } else return null
  }

  const formatCallType = (value) => {
    if (value !== null) {
      return value === 'NEWCALL' ? '신규통화' : '부재중'
    } else return null
  }

  const minutesIntoTimePicker = (minutes) => {
    if (minutes > 0) {
      const hours = Math.floor(minutes / 60)
      const mins = minutes % 60
      return dayjs().hour(hours).minute(mins)
    } else return 0
  }

  const onDepartmentListComplete = (departments) => {
    setSelectedDepartmentIds(_.map(departments, 'id'))
  }
  const {
    departments
  } = useDepartmentList(null, onDepartmentListComplete)
  const transformDepartment = departments.map(v => ({ label: v.name, value: v.id }))

  const onChangeDepartmentFilter = (newDepartments) => {
    setSelectedDepartmentIds(newDepartments)
  }

  const COLUMNWIDTH = 110
  const { categories } = useCategories()
  
  const projectTypeOptions = _.filter(categories, item =>
    ['df4a776b-be23-4e57-9522-6e60de3f4a0f', 'e9d60a0d-aa1a-469f-9fde-39fd19700f96'].includes(item.value)
  )

  useEffect(() => {
    const extractedData = _.map(currentFormData, record => ({
      projectType: _.get(record, 'projectType.id', null),
      projectSubType: _.get(record, 'projectSubType.id', null)
    }))
    setProjectTypeList(extractedData)
  }, [dataSource])

  const handleProjectTypeChange = (value, index, type = 'projectType') => {
    if (projectTypeList[index]) {
      projectTypeList[index][type] = value
      setProjectTypeList(projectTypeList)
    }
    // form.setFieldValue(['call', index, 'projectType'], null)
    getProjectTypeOptions(index)
    form.setFieldValue(['call', index, 'projectSubType'], null)
    setEditingKeys([...editingKeys]) // 사건구분, 세부사건구분이 업데이트 됨
  }
  const getProjectTypeOptions = (index) => {
    const result = _.filter(projectSubTypes, { type: _.get(projectTypeList, `[${index}].projectType`, null) })
    return result
  }

  // const findMatchingProjectType = (currentId) => {
  //   if (!projectTypesLoading) {
  //     const currentId = 'af78b58a-7d4f-4384-9dd4-30b972666633'
  //     const matchingItem = _.find(projectTypes, { id: currentId })
  //     const matchingId = matchingItem ? matchingItem.id : null
  //   }
  // }

  const getRecordViewerTableColumns = [
    {
      title: 'SN',
      dataIndex: 'id',
      key: 'id',
      width: calculateColumnWidth(callRecordsData, 'id', 'SN', 11, 100),
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: false
    },
    {
      title: '온오프구분',
      dataIndex: 'type',
      key: 'type',
      width: 130,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (type, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'type']}
                style={{ margin: 0 }}
              >
                <Select
                  style={{ width: '110px' }}
                  placeholder='온오프구분'
                  options={[
                    {
                      value: 'ONLINE',
                      label: '온라인'
                    },
                    {
                      value: 'OFFLINE',
                      label: '오프라인'
                    },
                    {
                      value: 'VISIT',
                      label: '방문&선임'
                    }
                  ]}
                />
              </Form.Item>)
            : (<OnOffTextColor type={type} />))
      }
    },
    {
      title: '통화날짜',
      dataIndex: 'inputDate',
      key: 'inputDate',
      width: 150,
      category: ['ONLINE'],
      fixed: 'left',
      editable: true,
      sort: true,
      render: (inputDate, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'inputDate']}
                style={{ margin: 0, width: 130 }}
              >
                <CustomDatePicker
                  placeholder='통화날짜'
                  date={convertToDayjs(inputDate)}
                  onChange={(date) => {
                    const newDateTime = date ? date.format('YYYY-MM-DD') : null
                    form.setFieldValue(['call', index, 'inputDate'], newDateTime)
                  }}
                />
              </Form.Item>)
            : (
              <div style={{ width: COLUMNWIDTH }}>
                {parseDateObj(inputDate)}
              </div>))
      }
    },
    {
      title: '통화시간',
      dataIndex: 'inputTime',
      key: 'inputTime',
      width: 130,
      category: ['ONLINE'],
      editable: true,
      sort: true,
      render: (inputTime, record, index) => {
        const removedSeconds = _.join(_.split(inputTime, ':').slice(0, 2), ':')
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'inputTime']}
                style={{ margin: 0, width: 110 }}
              >
                <CustomTimePicker
                  placeholder='통화시간'
                  time={parseTimeObj(inputTime, 'HH:mm')}
                  onChange={(time) => {
                    const newDateTime = time ? `${time.format('HH:mm:ss')}` : null
                    form.setFieldValue(['call', index, 'inputTime'], newDateTime)
                  }}
                />
              </Form.Item>)
            : (
              <div style={{ width: COLUMNWIDTH }}>
                {removedSeconds}
              </div>))
      }
    },
    {
      title: '콜식별',
      dataIndex: 'callType',
      key: 'callType',
      width: 120,
      category: ['ONLINE'],
      editable: true,
      render: (callType, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'callType']}
                style={{ margin: 0, width: 100 }}
              >
                <Select
                  placeholder='콜식별'
                  options={[
                    {
                      value: 'NEWCALL',
                      label: '신규통화',
                      key: '0'
                    },
                    {
                      value: 'MISSEDCALL',
                      label: '부재중',
                      key: '1'
                    }
                  ]}
                />
              </Form.Item>)
            : (
              <div style={{ width: COLUMNWIDTH }}>
                {formatCallType(callType)}
              </div>))
      }
    },
    {
      title: '부서',
      dataIndex: 'department',
      key: 'department',
      width: 220,
      category: ['ONLINE'],
      editable: true,
      render: (department, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'department', 'id']}
                style={{ margin: 0 }}
              >
                <Select
                  placeholder='부서'
                  options={transformDepartment}
                  allowClear
                />
              </Form.Item>)
            : (
              <div style={{ width: COLUMNWIDTH }}>
                {department !== undefined ? department.name : null}
              </div>))
      }
    },
    {
      title: '입력자_정',
      dataIndex: 'primaryAuthor',
      key: 'primaryAuthor',
      width: 100,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (primaryAuthor, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'primaryAuthor', 'id']}
                style={{ margin: 0, width: 90 }}
              >
                <Select
                  placeholder='NA'
                  options={users.map(v => ({ label: v.name, value: v.id }))}
                />
              </Form.Item>)
            : (
              <div style={{ width: COLUMNWIDTH }}>
                {primaryAuthor !== undefined ? primaryAuthor.name : null}
              </div>))
      }
    },
    {
      title: '입력자_부',
      dataIndex: 'secondaryAuthor',
      key: 'secondaryAuthor',
      width: 100,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (secondaryAuthor, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'secondaryAuthor', 'id']}
                style={{ margin: 0, width: 90 }}
              >
                <Select
                  placeholder='NA'
                  options={users.map(v => ({ label: v.name, value: v.id }))}
                />
              </Form.Item>)
            : (
              <div style={{ width: COLUMNWIDTH }}>
                {secondaryAuthor !== undefined ? secondaryAuthor.name : null}
              </div>
              ))
      }
    },
    {
      title: '사건구분',
      dataIndex: 'projectType',
      key: 'projectType',
      width: 340,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (projectType, record, index) => {
        const name = findMatchingProjectType(projectType?.id, projectTypes)
        return (
          isEditing(record)
            ? (
              <>
                {/* <Form.Item
                  {...record.field}
                  name={[index, 'projectType', 'id']}
                  style={{ margin: 0 }}
                >
                  <StyledButton
                    placeholder='사건구분'
                    onClick={() => projectTypeShowModal(index)}
                    style={{ width: 320, justifyContent: 'left' }}
                  >
                    {projectType !== null
                      ? (
                        <EllipsisContainer>
                          {findMatchingProjectType(projectType?.id, projectTypes)}
                        </EllipsisContainer>)
                      : (
                        <div style={{ color: '#d3d3d3' }}>사건구분</div>)}
                  </StyledButton>
                </Form.Item> */}
                <Form.Item
                  {...record.field}
                  name={[index, 'projectType', 'id']}
                  style={{ margin: 0 }}
                >
                  <div>
                    {isEditing(record)
                      ? (
                        <StyledButton
                          placeholder='사건구분'
                          onClick={() => projectTypeShowModal(index)}
                          style={{ width: 320, justifyContent: 'left' }}
                        >
                          {form.getFieldValue(['call', index, 'projectType']) 
                            ? (
                              <EllipsisContainer>
                                {findMatchingProjectType(
                                  form.getFieldValue(['call', index, 'projectType', 'id']),
                                  projectTypes
                                )}
                              </EllipsisContainer>)
                            : (
                              <div style={{ color: '#d3d3d3' }}>사건구분</div>
                              )}
                        </StyledButton>)
                      : (
                        <div div style={{ width: 320 }}>
                          {findMatchingProjectType(
                            form.getFieldValue(['call', index, 'projectType', 'id']),
                            projectTypes
                          ) || null}
                        </div>)}
                  </div>
                </Form.Item>
              </>)
            : (
              <div style={{ width: COLUMNWIDTH }}>
                {projectType !== undefined ? name : null}
              </div>))
      }
    },
    // {
    //   title: '세부사건구분',
    //   dataIndex: 'projectSubType',
    //   key: 'projectSubType',
    //   width: 140,
    //   category: ['ONLINE', 'OFFLINE', 'VISIT'],
    //   editable: true,
    //   render: (projectSubType, record, index) => {
    //     return (
    //       isEditing(record)
    //         ? (
    //           <Form.Item
    //             {...record.field}
    //             name={[index, 'projectSubType', 'id']}
    //             style={{ margin: 0, minWidth: 100 }}
    //           >
    //             <Select
    //               disabled={checkProejectTypeInput(form, record)}
    //               placeholder='세부사건구분'
    //               options={getProjectTypeOptions(index)}
    //               // options={_.filter(projectSubTypes, { type: _.get(projectTypeList, `[${index}].projectType`, null) })}
    //             />
    //           </Form.Item>)
    //         : (
    //           <div style={{ width: COLUMNWIDTH }}>
    //             {(projectSubType !== null && projectSubType !== undefined) ? projectSubType.name : null}
    //           </div>)
    //     )
    //   }
    // },
    {
      title: '의뢰인명',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      fixed: 'left',
      editable: true,
      render: (name, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'name']}
                style={{ margin: 0, width: 80 }}
                placeholder='이름'
              >
                <Input
                  placeholder='의뢰인명'
                />
              </Form.Item>)
            : (name))
      }
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      width: 90,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (gender, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'gender']}
                style={{ margin: 0 }}
              >
                <Select
                  placeholder='성별'
                  options={[
                    {
                      value: 'MALE',
                      label: '남성'
                    },
                    {
                      value: 'FEMALE',
                      label: '여성'
                    }
                  ]}
                />
              </Form.Item>)
            : (formatGender(gender)))
      }
    },
    {
      title: '연령',
      dataIndex: 'age',
      key: 'age',
      width: 80,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (age, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'age']}
                style={{ margin: 0 }}
              >
                <InputNumber
                  placeholder='연령'
                  changeOnWheel
                  min={0}
                  style={{ width: 70 }}
                />
              </Form.Item>
              )
            : (age))
      }
    },
    {
      title: '전화번호',
      dataIndex: 'phone',
      key: 'phone',
      width: 160,
      fixed: 'left',
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (phone, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'phone']}
                style={{ margin: 0, width: 140 }}
              >
                <Input
                  placeholder='전화번호'
                />
              </Form.Item>)
            : (phone))
      }
    },
    {
      title: '주소',
      dataIndex: 'address',
      key: 'address',
      width: calculateColumnWidth(callRecordsData, 'address', '주소', 15, 130),
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (address, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'address']}
                style={{ margin: 0, minWidth: 120 }}
              >
                <Input
                  placeholder='주소'
                  // // defaultValue={address || undefined}
                />
              </Form.Item>)
            : (address))
      }
    },
    {
      title: '방문경로',
      dataIndex: 'via',
      key: 'via',
      width: calculateColumnWidth(callRecordsData, 'via', '방문경로', 14, 110),
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (via, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'via']}
                style={{ margin: 0, minWidth: 100 }}
              >
                <Input
                  placeholder='방문경로'
                />
              </Form.Item>)
            : (via))
      }
    },
    {
      title: '예상사건명',
      dataIndex: 'expectedCaseName',
      key: 'expectedCaseName',
      width: calculateColumnWidth(callRecordsData, 'expectedCaseName', '사건명', 14, 130),
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (expectedCaseName, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'expectedCaseName']}
              >
                <Input
                  placeholder='예상사건명'
                />
              </Form.Item>)
            : (expectedCaseName))
      }
    },
    {
      title: '관할',
      dataIndex: 'jurisdiction',
      key: 'jurisdiction',
      width: calculateColumnWidth(callRecordsData, 'jurisdiction', '관할', 15, 130),
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (jurisdiction, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'jurisdiction']}
                style={{ margin: 0, minWidth: 100 }}
              >
                <Input
                  placeholder='관할'
                />
              </Form.Item>)
            : (jurisdiction))
      }
    },
    {
      title: '사건번호등',
      dataIndex: 'caseNumber',
      key: 'caseNumber',
      width: calculateColumnWidth(callRecordsData, 'caseNumber', '사건번호등', 22, 130),
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (caseNumber, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'caseNumber']}
                initialValue={caseNumber}
              >
                <Input
                  placeholder='사건번호등'
                />
                {/* <StyledTag
                  color='red'
                  onClick={() => showModal('duplication')}
                >
                  중복
                </StyledTag> */}
                {/* <DuplicationStyledModal open={duplicationModalOpen} onOk={handleOk} onCancel={handleCancel}>
                  <Alert type='error' message='중복된 계약이 있습니다.' banner />
                  <h4>계약 기록</h4>
                  <Table
                    rowSelection={{
                      type: 'radio',
                      ...rowSelection
                    }}
                    pagination={false}
                    columns={contractDuplicationColumns}
                    callRecordsData={contractDuplication}
                  />
                </DuplicationStyledModal> */}
              </Form.Item>)
            : (caseNumber))
      }
    },
    {
      title: '담당자',
      dataIndex: 'manager',
      key: 'manager',
      width: 120,
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (manager, record, index) => {
        // const joinArray = manager?.join()
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'manager', 'id']}
                style={{ margin: 0, width: 100 }}
              >
                <Select
                  // mode='multiple'
                  placeholder='담당자'
                  options={users.map(v => ({ label: v.name, value: v.id }))}
                />
              </Form.Item>)
            : (manager !== undefined ? manager.name : null))
      }
    },
    {
      title: '소가/피해금',
      dataIndex: 'amount',
      key: 'amount',
      width: 180,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (amount, record, index) => {
        if (amount === null) {
          amount = 0
        }
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'amount']}
                style={{ margin: 0, width: 160 }}
              >
                <InputNumber
                  changeOnWheel
                  placeholder='소가/피해금'
                  min={0}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  addonAfter='원'
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>)
            : (<MoneyContainer value={amount} />))
      }
    },
    {
      title: '내용',
      dataIndex: 'content',
      key: 'content',
      width: 250,
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (content, record, index) => {
        let firstLine = ''
        if (content !== null && content !== undefined) {
          const splitLine = content.split('\n')
          firstLine = splitLine[0]
        }
        return (
          isEditing(record)
            ? (
              <>
                <Form.Item
                  {...record.field}
                  name={[index, 'content']}
                  style={{ margin: 0 }}
                >
                  <StyledButton
                    placeholder='내용을 입력해주세요'
                    onClick={() => showContentModal(index)}
                    style={{ width: '230px', justifyContent: 'left' }}
                  >
                    {firstLine.length > 0
                      ? (
                        <EllipsisContainer>
                          {firstLine}
                        </EllipsisContainer>)
                      : (
                        <div style={{ color: '#d3d3d3' }}>내용을 입력해주세요</div>)}
                  </StyledButton>
                </Form.Item>
              </>)
            : (
              <Popover
                placement='bottom'
                content={
                  <TextArea defaultValue={content || undefined} disabled={disabled} style={{ width: '500px', minHeight: '500px', background: '#fff', color: '#000' }} />
                }
              >
                <EllipsisContainer>
                  {firstLine}
                </EllipsisContainer>
              </Popover>))
      }
    },
    {
      title: '방문지사',
      dataIndex: 'visitDepartment',
      key: 'visitDepartment',
      width: 220,
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (visitDepartment, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'visitDepartment', 'id']}
                style={{ margin: 0 }}
              >
                <Select
                  placeholder='방문지사'
                  // defaultValue={getData(record, 'visitDepartment')}
                  options={_.map(departments, parseMetaToSelectOptions)}
                  allowClear
                />
              </Form.Item>)
            : (visitDepartment !== undefined ? visitDepartment.name : null))
      }
    },
    {
      title: '방문예약일자',
      dataIndex: 'visitReservedDate',
      key: 'visitReservedDate',
      width: 150,
      category: ['VISIT'],
      editable: true,
      render: (visitReservedDate, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'visitReservedDate']}
                style={{ margin: 0, width: 130 }}
              >
                <CustomDatePicker
                  placeholder='방문예약일자'
                  date={convertToDayjs(visitReservedDate)}
                  onChange={(date) => {
                    const newDateTime = date ? date.format('YYYY-MM-DD') : null
                    form.setFieldValue(['call', index, 'visitReservedDate'], newDateTime)
                  }}
                />
              </Form.Item>)
            : (parseDateObj(visitReservedDate)))
      }
    },
    {
      title: '방문예약시간',
      dataIndex: 'visitReservedTime',
      key: 'visitReservedTime',
      width: 130,
      category: ['ONLINE'],
      editable: true,
      render: (visitReservedTime, record, index) => {
        const removedSeconds = _.join(_.split(visitReservedTime, ':').slice(0, 2), ':')
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'visitReservedTime']}
                style={{ margin: 0, minWidth: 110 }}
              >
                <CustomTimePicker
                  placeholder='방문예약시간'
                  time={parseTimeObj(visitReservedTime, 'HH:mm')}
                  onChange={(time) => {
                    const newDateTime = time ? `${time.format('HH:mm:ss')}` : null
                    form.setFieldValue(['call', index, 'visitReservedTime'], newDateTime)
                  }}
                />
              </Form.Item>)
            : (removedSeconds))
      }
    },
    {
      title: '예약상황',
      dataIndex: 'reservationStatus',
      key: 'reservationStatus',
      width: 120,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (reservationStatus, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'reservationStatus', 'id']}
                style={{ margin: 0 }}
              >
                <Select
                  style={{ width: '110px' }}
                  placeholder='예약상황'
                  options={callResercationStatus.map(v => ({ label: v.name, value: v.id }))}
                />
              </Form.Item>)
            : getLabelByValue(callResercationStatus.map(v => ({ label: v.name, value: v.id })), reservationStatus?.id))
      }
    },
    {
      title: '예약취소사유',
      dataIndex: 'reservationCancelReason',
      key: 'reservationCancelReason',
      width: calculateColumnWidth(callRecordsData, 'reservationCancelReason', '예약취소사유', 15, 150),
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (reservationCancelReason, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'reservationCancelReason']}
                style={{ margin: 0, minWidth: 100 }}
              >
                <Input
                  placeholder='예약취소사유'
                  // defaultValue={reservationCancelReason || undefined}
                />
              </Form.Item>)
            : (reservationCancelReason))
      }
    },
    {
      title: '콜 수',
      dataIndex: 'callCount',
      key: 'callCount',
      width: 90,
      category: ['ONLINE'],
      editable: true,
      render: (callCount, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'callCount']}
                style={{ margin: 0 }}
              >
                <InputNumber
                  changeOnWheel
                  min={0}
                  placeholder='콜 수'
                  style={{ width: 70 }}
                />
              </Form.Item>
              )
            : (callCount))
      }
    },
    {
      title: '방문일',
      dataIndex: 'visitedDate',
      key: 'visitedDate',
      width: 150,
      category: ['VISIT'],
      editable: true,
      render: (visitedDate, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'visitedDate']}
                style={{ margin: 0 }}
              >
                <CustomDatePicker
                  placeholder='방문일'
                  date={convertToDayjs(visitedDate)}
                  onChange={(date) => {
                    const newDateTime = date ? date.format('YYYY-MM-DD') : null
                    form.setFieldValue(['call', index, 'visitedDate'], newDateTime)
                  }}
                />
              </Form.Item>)
            : (
              <div style={{ width: COLUMNWIDTH }}>
                {parseDateObj(visitedDate)}
              </div>))
      }
    },
    {
      title: '방문시간',
      dataIndex: 'visitedTime',
      key: 'visitedTime',
      width: 130,
      category: ['VISIT'],
      editable: true,
      render: (visitedTime, record, index) => {
        const removedSeconds = _.join(_.split(visitedTime, ':').slice(0, 2), ':')
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'visitedTime']}
                style={{ margin: 0, width: 110 }}
              >
                <CustomTimePicker
                  placeholder='방문시간'
                  time={parseTimeObj(visitedTime, 'HH:mm')}
                  onChange={(time) => {
                    const newDateTime = time ? `${time.format('HH:mm:ss')}` : null
                    form.setFieldValue(['call', index, 'visitedTime'], newDateTime)
                  }}
                />
              </Form.Item>)
            : (
              <div style={{ width: COLUMNWIDTH }}>
                {removedSeconds}
              </div>))
      }
    },
    {
      title: '방문자',
      dataIndex: 'visitor',
      key: 'visitor',
      width: 115,
      category: ['VISIT'],
      render: (visitor, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'visitor']}
                style={{ margin: 0, width: 90 }}
              >
                <Input
                  placeholder='방문자'
                />
              </Form.Item>)
            : (visitor))
      }
    },
    {
      title: '상담변호사',
      dataIndex: 'counselLawyerIds',
      key: 'counselLawyerIds',
      width: 260,
      category: ['VISIT'],
      editable: true,
      render: (counselLawyerIds, record, index) => {
        const matchedUsers = findUsersUsingIds(users, counselLawyerIds)
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'counselLawyerIds']}
                style={{ margin: 0 }}
              >
                <Select
                  mode='multiple'
                  placeholder='상담변호사'
                  // onChange={(value) => handleChange(record.key, 'counselLawyers', value)}
                  onChange={(lawyer) => {
                    form.setFieldValue(['call', index, 'counselLawyers'], lawyer)
                  }}
                  options={users.map(v => ({
                    label: v.name,
                    value: v.id,
                    group: v.group.name,
                    departments: v.departments
                  }))}
                  optionRender={(option) => {
                    return (
                      <Space>
                        <UserIcon group={option.data.group} name={option.data.label} />
                        <DepartmentTag departments={option.data.departments} />
                      </Space>
                    )
                  }}
                />
              </Form.Item>)
            : (formatArrayToComma(matchedUsers)))
      }
    },
    // 임시 주석: 고급 검색
    // {
    //   title: '상담변호사',
    //   dataIndex: 'counselLawyers',
    //   key: 'counselLawyers',
    //   width: calculateColumnWidth(callRecordsData, 'counselLawyers', '상담변호사', 24, 270),
    //   category: ['VISIT'],
    //   editable: true,
    //   render: (counselLawyers, record, index) => {
    //     const joinArray = counselLawyers?.join()
    //     return (
    //       isEditing(record)
    //         ? (
    //           <Form.Item
    //             name={['call', record.key, 'counselLawyers']}
    //             style={{ margin: 0, width: calculateColumnWidth(callRecordsData, 'counselLawyers', '상담변호사', 22, 260) }}
    //           >
    //             <Select
    //               mode='multiple'
    //               // defaultValue={counselLawyers}
    //               // value={counselLawyers}
    //               popupMatchSelectWidth={advancedSearch ? 600 : false}
    //               placeholder='상담변호사'
    //               // onChange={handleChanges}
    //               options={users.map(v => ({
    //                 label: v.name,
    //                 value: v.id,
    //                 group: v.group.name,
    //                 departments: v.departments
    //               }))}
    //               // optionRender={(option) => {
    //               //   return (
    //               //     <Space>
    //               //       <UserIcon group={option.data.group} name={option.data.label} />
    //               //       <DepartmentTag departments={option.data.departments} />
    //               //     </Space>
    //               //   )
    //               // }}
    //               dropdownRender={menu => {
    //                 return (
    //                   <>
    //                     {advancedSearch
    //                       ? (
    //                         <RowGapWrapper>
    //                           <SearchParamsFilter
    //                             loading={loading}
    //                             // selectedItems={selectedItems}
    //                             // onChange={e => onChangeUser(e)} // 고급 검색의 직원
    //                             onChange={(value) => handleChange(record.id, 'counselLawyers', value)}
    //                             persistElement={<BaseText text='작성자' />}
    //                             onChangeDepartmentFilter={e => onChangeDepartmentFilter(e)}
    //                             selectedDepartmentOptions={selectedDepartmentIds}
    //                             // openStatusByKey={openStatusByKey}
    //                             options={users.map(v => ({ label: v.name, value: v.id }))}
    //                             multiple
    //                             departments={departments}
    //                             // {...props}
    //                           />
    //                         </RowGapWrapper>
    //                         )
    //                       : (
    //                         <>
    //                           <Space style={{ padding: '0 5px 4px' }}>
    //                             <Button
    //                               onClick={handleAdvancedSearch}
    //                             >
    //                               <SearchOutlined />
    //                               고급 검색
    //                             </Button>
    //                           </Space>
    //                           {menu}
    //                         </>)}
    //                   </>
    //                 )
    //               }}
    //               onDropdownVisibleChange={(open) => {
    //                 if (!open) {
    //                   setAdvancedSearch(false)
    //                 }
    //                 setIsDropdownOpen(open)
    //               }}
    //               open={isDropdownOpen}
    //             />
    //           </Form.Item>)
    //         : (joinArray)
    //     )
    //   }
    // },
    {
      title: '상담직원',
      dataIndex: 'counselEmployeeIds',
      key: 'counselEmployeeIds',
      width: calculateColumnWidth(callRecordsData, 'counselEmployees', '상담직원', 4, 260),
      category: ['VISIT'],
      editable: true,
      render: (counselEmployeeIds, record, index) => {
        const matchedUsers = findUsersUsingIds(users, counselEmployeeIds)
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'counselEmployeeIds']}
                style={{ margin: 0 }}
              >
                <Select
                  mode='multiple'
                  placeholder='상담직원'
                  onChange={(lawyer) => {
                    form.setFieldValue(['call', index, 'counselEmployees'], lawyer)
                  }}
                  options={users.map(v => ({
                    label: v.name,
                    value: v.id,
                    group: v.group.name,
                    departments: v.departments
                  }))}
                  optionRender={(option) => {
                    return (
                      <Space>
                        <UserIcon group={option.data.group} name={option.data.label} />
                        <DepartmentTag departments={option.data.departments} />
                      </Space>
                    )
                  }}
                />
              </Form.Item>)
            : (formatArrayToComma(matchedUsers)))
      }
    },
    {
      title: '데이터 입력자',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: calculateColumnWidth(callRecordsData, 'createdBy', '데이터 입력자', 10, 140),
      category: ['VISIT'],
      editable: true,
      render: (createdBy, record, index) => {
        if (createdBy !== undefined) {
          return (createdBy.name)
        } else return null
      }
    },
    {
      title: '신규방문 수',
      dataIndex: 'nVisit',
      key: 'nVisit',
      width: 130,
      category: ['VISIT'],
      editable: true,
      render: (nVisit, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'nVisit']}
                style={{ margin: 0 }}
              >
                <InputNumber
                  changeOnWheel
                  min={0}
                  placeholder='신규방문 수'
                  style={{ width: '110px' }}
                />
              </Form.Item>
              )
            : (nVisit))
      }
    },
    {
      title: '재방문',
      dataIndex: 'nRevisit',
      key: 'nRevisit',
      width: 120,
      category: ['VISIT'],
      editable: true,
      render: (nRevisit, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'nRevisit']}
                style={{ margin: 0 }}
              >
                <InputNumber
                  changeOnWheel
                  min={0}
                  placeholder='재방문수'
                  style={{ width: '100px' }}
                />
              </Form.Item>)
            : (nRevisit))
      }
    },
    {
      title: '계약자',
      dataIndex: 'contractor',
      key: 'contractor',
      width: 110,
      category: ['VISIT'],
      render: (contractor, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'contractor']}
                style={{ margin: 0, minWidth: 80 }}
              >
                <Input
                  placeholder='계약자'
                />
              </Form.Item>)
            : (contractor))
      }
    },
    {
      title: '선임일',
      dataIndex: 'assigned',
      key: 'assigned',
      width: 150,
      category: ['VISIT'],
      editable: true,
      render: (assigned, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'assigned']}
                style={{ margin: 0, width: 130 }}
              >
                <CustomDatePicker
                  placeholder='선임일'
                  date={convertToDayjs(assigned)}
                  onChange={(date) => {
                    const newDateTime = date ? date.format('YYYY-MM-DD') : null
                    form.setFieldValue(['call', index, 'assigned'], newDateTime)
                  }}
                />
              </Form.Item>)
            : (parseDateObj(assigned)))
      }
    },
    {
      title: '선임',
      dataIndex: 'nAssign',
      key: 'nAssign',
      width: 100,
      category: ['VISIT'],
      editable: true,
      render: (nAssign, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'nAssign']}
                style={{ margin: 0, width: 130 }}
              >
                <InputNumber
                  changeOnWheel
                  min={0}
                  placeholder='선임'
                  style={{ width: 80 }}
                />
              </Form.Item>)
            : (nAssign))
      }
    },
    {
      title: '사건종류',
      dataIndex: 'assignType',
      key: 'assignType',
      width: 120,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (assignType, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'assignType', 'id']}
                style={{ margin: 0, width: 100 }}
              >
                <Select
                  placeholder='사건종류'
                  options={[
                    {
                      value: 'YK-ASSIGN-TYPE-1',
                      label: '신규',
                      key: '0'
                    },
                    {
                      value: 'YK-ASSIGN-TYPE-2',
                      label: '상급심',
                      key: '1'
                    },
                    {
                      value: 'YK-ASSIGN-TYPE-3',
                      label: '별건',
                      key: '2'
                    }
                  ]}
                />
              </Form.Item>)
            : (
              <div style={{ width: COLUMNWIDTH }}>
                {assignType !== undefined ? assignType.name : null}
              </div>))
      }
    },
    {
      title: '사임취소일자',
      dataIndex: 'cancelled',
      key: 'cancelled',
      width: 150,
      category: ['VISIT'],
      editable: true,
      render: (cancelled, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'cancelled']}
                style={{ margin: 0, width: 130 }}
              >
                <CustomDatePicker
                  placeholder='사윔취소일자'
                  date={convertToDayjs(cancelled)}
                  onChange={(date) => {
                    const newDateTime = date ? date.format('YYYY-MM-DD') : null
                    form.setFieldValue(['call', index, 'cancelled'], newDateTime)
                  }}
                />
              </Form.Item>)
            : (parseDateObj(cancelled)))
      }
    },
    {
      title: '사유',
      dataIndex: 'cancelReason',
      key: 'cancelReason',
      width: calculateColumnWidth(callRecordsData, 'cancelReason', '사유', 13, 200),
      category: ['VISIT'],
      editable: true,
      render: (cancelReason, record, index) => {
        return (
          isEditing(record)
            ? (
              <Form.Item
                {...record.field}
                name={[index, 'cancelReason']}
                style={{ margin: 0, minWidth: 100 }}
              >
                <Input
                  placeholder='사유'
                />
              </Form.Item>)
            : (cancelReason))
      }
    },
    {
      title: '계약상태',
      dataIndex: 'contractState',
      key: 'contractState',
      width: 100,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      fixed: 'right',
      editable: true,
      render: (contractState, record, index) => {
        const closed = record.contractRecord !== null ? (record.contractRecord?.closed !== null) ? true : null : null
        const overdue = _.some(contractRecordsData, (data) => data.id === record.contractRecord?.id)
        if (closed === true) {
          contractState = 'CLOSE'
        } else if (overdue === true) {
          contractState = 'OVERDUE'
        } else if (record.contractRecord !== null) {
          contractState = 'PROGRESS'
        } else if (record.nAssign > 0) {
          contractState = 'CONTRACT'
        } else {
          contractState = 'NOCONTRACT'
        }
        return (
          <ContractStateColor state={contractState} record={record} handleNavigateContractState={handleNavigateContractState} />
        )
      }
    },
    {
      title: '수정',
      dataIndex: 'edit',
      key: 'edit',
      width: 50,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      fixed: 'right',
      render: (_, record, index) => {
        const editable = isEditing(record)
        const checkboxSelected = isCheckboxSelected(record)
        return (
          <EditSaveRowButton
            editable={editable}
            record={record}
            disabled={checkboxSelected}
            htmlType='submit'
            onSave={() => saveCallRecord(record.id, record)}
            onEdit={() => edit(record.id, record, index)}
          />
        )
      }
    }
  ]

  const getRecordViewerTableGroupColumns = [
    {
      title: '의뢰인명',
      dataIndex: 'name',
      key: 'name',
      category: ['ONLINE', 'OFFLINE', 'VISIT']
    }
  ]

  const getRecordViewerTableTypeGroupColumns = [
    {
      title: '사건구분',
      dataIndex: 'projectType',
      key: 'projectType',
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      render: (projectType) => {
        let name
        if (projectType === 'null') {
          name = '미배치'
        } else {
          // name = projectType
          name = 'aa'
        }
        return (
          <div>{name}</div>
        )
      }
    }
  ]

  const saveCallRecord = (key, record) => {
    const currentRows = form.getFieldValue('call') || []
    const selectedRow = currentRows.filter((item) => key.includes(item.key))
    onEditCallRecord(key, selectedRow[0])
    _.remove(editingKeys, (item) => item === key)
  }

  const saveAll = () => {
    const currentRows = form.getFieldValue('call') || []
    for (let i = 0; i < currentRows.length; i++) {
      onEditCallRecord(currentRows[i].key, currentRows[i])
    }
    try {
      _.remove(editingKeys)
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }
  const edit = (id, record, index) => {
    setEditingKeys([...editingKeys, id])
  }
  const editAll = (data) => {
    try {
      setEditingKeys(_.map(data, 'id'))
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }
  return {
    getRecordViewerTableColumns,
    getRecordViewerTableGroupColumns,
    getRecordViewerTableTypeGroupColumns,
    editingKeys,
    saveAll,
    editAll
  }
}
