import { gql } from '@apollo/client'

export const getContractRecordList = gql`
  query GetContractRecordList($sortBy: SortBy, $filter: GetContractRecordListFilter, $groupBy: String, $offset: Int, $limit: Int) {
    GetContractRecordList(sortBy: $sortBy, filter: $filter, groupBy: $groupBy, offset: $offset, limit: $limit) {
      totalCount
      ... on GetContractRecordListResponse {
        totalCount
        records {
          id
          created
          createdBy {
            id
            name
          }
          updated
          updatedBy {
            id
            name
          }
          closed
          closedBy {
            id
            name
          }
          deleted
          deletedBy {
            id
            name
          }
          callRecord {
            id
          }
          project {
            id
            name
          }
          paymentComments {
            id
          }
          paymentRecords {
            id
            createdBy {
              id
              name
            }
            updatedBy {
              id
              name
            }
            created
            updated
            dueDate
            dueAmount
            category
            periodic
            periodicId
            deposits {
              id
              category
              received
              receivedAmount
              type
              expenseProofMethod
              created
              createdBy {
                id
                name
              }
            }
          }
          caseNumber
          caseName
          projectTypeStr
          projectType {
            id
            name
          }
          department {
            id
            name
          }
          assignedLawyers {
            id
            name
          }
          name
          representer
          phone
          address
          email
          opponent
          jurisdiction
          registrationNumber
        }
        totalDueAmount
        totalReceived
      }
      ... on GetContractRecordGroupedListResponse {
        totalCount
        groups {
          department {
            id
            name
          }
          projectTypeStr
          projectType {
            id
            name
          }
          totalCount
          records {
            id
            created
            createdBy {
              id
              name
            }
            updated
            updatedBy {
              id
              name
            }
            closed
            closedBy {
              id
              name
            }
            deleted
            deletedBy {
              id
              name
            }
            callRecord {
              id
            }
            project {
              id
              name
            }
            paymentRecords {
              id
              createdBy {
                id
                name
              }
              updatedBy {
                id
                name
              }
              created
              updated
              dueDate
              dueAmount
              category
              periodic
              periodicId
              deposits {
                id
                category
                received
                receivedAmount
                type
                expenseProofMethod
                created
                createdBy {
                  id
                  name
                }
              }
            }
            caseNumber
            caseName
            projectTypeStr
            projectType {
              id
              name
            }
            department {
              id
              name
            }
            assignedLawyers {
              id
              name
            }
            name
            representer
            phone
            address
            email
            opponent
            jurisdiction
            registrationNumber
          }
          totalDueAmount
          totalReceived
        }
        totalDueAmount
        totalReceived
      }
    }
  }
`

export const getOverdueContractRecordList = gql`
  query GetContractRecordList($sortBy: SortBy, $filter: GetContractRecordListFilter, $groupBy: String, $offset: Int, $limit: Int) {
    GetContractRecordList(sortBy: $sortBy, filter: $filter, groupBy: $groupBy, offset: $offset, limit: $limit) {
      totalCount
      ... on GetContractRecordListResponse {
        records {
          id
        }
      }
    }
  }
`

export const getPaymentContractRecordList = gql`
  query GetContractRecordList($sortBy: SortBy, $filter: GetContractRecordListFilter, $groupBy: String, $offset: Int, $limit: Int) {
    GetContractRecordList(sortBy: $sortBy, filter: $filter, groupBy: $groupBy, offset: $offset, limit: $limit) {
      totalCount
      ... on GetContractRecordListResponse {
        totalCount
        totalDueAmount
        totalReceived
      }
      ... on GetContractRecordGroupedListResponse {
        totalCount
        totalDueAmount
        totalReceived
      }
    }
  }
`
