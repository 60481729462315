import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { parseDateObj } from '../../../../common/util'
import _ from 'lodash'

export const getUpdatedSearchParams = (type, key, value, sp) => {
  if (_.isArray(value)) {
    sp.delete(`${type}_${key}`)
    value.forEach(v => {
      if (type === 'filter' && key === 'datetimeRange') {
        sp.append(`${type}_${key}`, parseDateObj(v, 'YYYY-MM-DD HH:MM'))
      } else {
        sp.append(`${type}_${key}`, v)
      }
    })
  } else {
    sp.set(`${type}_${key}`, value)
  }
  return sp
}

const defSearchParams = {
  group_time: 'all', // monthly, daily, weekly, all
  group_item: 'all', // call
  filter_recordType: [], // 전체, 온라인, 오프라인, 방문&선임
  filter_caseNumber: [], // 사건번호
  filter_datetimeRange: [],
  filter_departmentId: [], // 본사 부서
  filter_userIds: [],
  filter_contractTotal: [], // 계약 총 금액
  filter_assignedLawyerId: [], // 담당변호사
  filter_projectTypeId: [], // 사건구분
  filter_projectTypeStr: [] // 사건구분
}

export const useRecordFilterSearchParams = (initParams = defSearchParams) => {
  const [searchParams, setSearchParams] = useSearchParams(initParams)
  const searchString = searchParams.toString()
  const [time, setTime] = useState('daily')
  const onChangeMeta = (type, key, value) => {
    if (key === 'time') {
      setTime(value)
    }
    const newSearchParams = getUpdatedSearchParams(type, key, value, searchParams)
    setSearchParams(newSearchParams)
  }
  const onRemoveItemFromFilter = (key, value) => {
    onChangeMeta('filter', value, _.filter(meta.filter[key], v => v !== value))
  }
  const onResetFilterAndGroup = () => {
    setSearchParams(defSearchParams)
  }

  const meta = {
    group: {
      time: 'all', // monthly, daily, weekly, all
      item: 'all' // call
    },
    filter: {
      recordType: null,
      caseNumber: null,
      datetimeRange: null,
      departmentId: null,
      userIds: null,
      contractTotal: null,
      assignedLawyerId: null,
      projectTypeId: null,
      projectTypeStr: null
    }
  }
  const a = ['group', 'filter']
  const b = ['time', 'item']
  const c = ['recordType', 'caseNumber', 'datetimeRange', 'departmentId', 'userIds', 'contractTotal', 'assignedLawyerId', 'projectTypeId', 'projectTypeStr']
  a.forEach(v1 => {
    if (v1 === 'group') {
      b.forEach(v2 => {
        meta[v1][v2] = searchParams.get(`${v1}_${v2}`)
      })
    } else {
      c.forEach(v2 => {
        meta[v1][v2] = [...searchParams.getAll(`${v1}_${v2}`)]
      })
    }
  })

  return {
    meta,
    searchParams,
    setSearchParams,
    getUpdatedSearchParams,
    onChangeMeta,
    onResetFilterAndGroup,
    onRemoveItemFromFilter
  }
}
