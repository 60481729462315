import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Buffer } from 'buffer'
import { fastapiUrl } from './config'
import { Typography } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'

dayjs.extend(customParseFormat)
dayjs.locale('ko')

export const parseMinutesToHourMin = (totalMinutes = 0, hourUnit = '시간', minUnit = '분') => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return `${hours <= 0 ? '' : `${hours}${hourUnit} `}${minutes === 0 ? (hours === 0) ? `0${minUnit}` : '' : `${minutes}${minUnit} `}`
}
export const getHoursMinsFromMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return {
    hours,
    minutes
  }
}
export const convertMomentToMinutes = (_moment) => {
  return _moment.hour() * 60 + _moment.minute()
}

export const parseDateObj = (date, format = 'YYYY-MM-DD') => {
  if (!date) return null
  return dayjs(date).format(format)
}

export const parseTimeObj = (date, format = 'HH:mm') => {
  if (!date) return null
  return dayjs(date, 'HH:mm')
}

export const convertToDayjs = (date) => {
  if (date === undefined) {
    return null
  }
  if (date !== null) {
    return dayjs(date)
  }
}

export const uuidv4 = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

export const addMonthToDate = (year, month) => {
  return new Date(year, month + 1, 1)
}

export const getOffsetFromPageAndLimit = (page, limit) => {
  return (page - 1) * limit
}

export const downloadExcel = (data, name = '목록') => {
  const link = document.createElement('a')
  link.setAttribute('download', `${name}.xlsx`)
  const buffer = new Buffer.from(data, 'base64')
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  const blobUrl = window.URL.createObjectURL(blob)
  link.href = blobUrl
  link.click()
  URL.revokeObjectURL(blobUrl)
}

export const sleep = (ms) => {
  return new Promise((r) => setTimeout(r, ms))
}

export const downloadFile = async ({ id, originalFilename }) => {
  const downloadUrl = fastapiUrl + `/attachments/${id}`
  const link = document.createElement('a')
  link.href = downloadUrl
  link.target = '_blank'
  link.setAttribute('download', '')
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

// 테이블 데이터와 테이블 명칭 길이를 확인 후 가장 긴 값을 return
export const calculateColumnWidth = (data, accessor, headerText, multiple = 9, min = 100) => {
  if (data[0] !== null && data[0] !== undefined) {
    const maxContentLength = Math.max(
      ...data.map(item => String(item[accessor]).length),
      headerText.length
    )
    const currentLength = maxContentLength * multiple
    if (currentLength < min) {
      return min
    } else {
      return currentLength
    }
  }
}

export const BaseText = ({ text }) => {
  return <Typography.Text style={{ cursor: 'pointer', fontWeight: '300' }}>{text}</Typography.Text>
}
