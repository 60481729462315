import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StatTable } from '../components/statTable/StatTable'
import { todayDate } from '../components/common/CommonFunction'
import { useConfirmModal } from '../hooks/common/useConfirmModal'
import { ConfirmModal } from '../../common/components/ConfirmModal'
import { CustomSearch } from '../../common/components/CustomSearch'
import CallRecordFilter from '../components/filter/CallRecordFilter'
import { getCurrentUser } from '../../timeCharge/apollo/queries'
import { useUserList } from '../../common/hooks/apollo/useUserList'
import { useCategories } from '../hooks/callRecord/useCategories'
import { useCallRecordList } from '../hooks/apollo/useCallRecordList'
import { useRecordTable } from '../hooks/callRecord/useCallRecordTable'
import { BlockModal } from '../../timeCharge/components/common/BlockModal'
import { useProjectSubTypes } from '../hooks/callRecord/useProjectSubTypes'
import { useScratchPadTable } from '../hooks/callRecord/useScratchPadTable'
import { useBlockModal } from '../../timeCharge/hooks/common/useBlockModal'
import { CallCustomTable } from '../components/customTable/CallCustomTable'
import { useDepartmentList } from '../hooks/common/filter/useDepartmentList'
import { useCallRecordRemove } from '../hooks/callRecord/useCallRecordRemove'
import { HeaderContentFooter } from '../../common/components/HeaderContentFooter'
import { navigateToCallRecord } from '../cacheAndNavigation/callRecord/navigation'
import { useCallRecordFilter } from '../hooks/filter/callRecord/useCallRecordFilter'
import { useContractRecordList } from '../hooks/apollo/contractRecord/useContractRecordList'
import { useRecordViewerColumns } from '../hooks/contractRecord/table/useRecordViewerColumns'
import { useCallRerservationStatusList } from '../hooks/callRecord/useCallRerservationStatusList'
import { ScratchPadCustomTable } from '../components/scratchPadCustomTable/ScratchPadCustomTable'
import { useDefaultDateRangeFilter } from '../../timeCharge/hooks/filter/useDefaultDateRangeFilter'
import { navigateToContractRecordDetail } from '../cacheAndNavigation/contractRecordDetail/navigation'
import { useContractRecordAddEditRemove } from '../hooks/contractRecord/useContractRecordAddEditRemove'
import { ColumnGapWrapper, CustomSearchContainer, ResponsiveGapContainer } from '../../common/Theme/style'

import { Form } from 'antd'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import { ProjectTypeModal } from '../components/recordCustomTable/ProjectTypeModal'
import { projectTypesData } from '../../mockdata'
import { ContentModal } from '../components/recordCustomTable/ContentModal'
import { useProjectTypes } from '../../timeCharge/hooks/apollo/useProjectTypes'

const CallRecordContainer = () => {
  const [searchQuery, setSearchQuery] = useState(null) // 의뢰인/전화번호 검색어
  const [dataSource, setDataSource] = useState([]) // 콜 기록 데이터
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const localStorageScratchPadData = localStorage.getItem('scratchPad')
  const initialData = localStorageScratchPadData ? JSON.parse(localStorageScratchPadData) : []
  const [scratchPadData, setScratchPadData] = useState(initialData)
  const [statViewer, setStatViewer] = useState(false)
  const [form] = Form.useForm()
  const [scratchpadForm] = Form.useForm()
  const [scratchPadList, setScratchPadList] = useState(scratchpadForm.getFieldValue('scratchPad'))
  const [currentForm, setCurrentForm] = useState(form.getFieldValue('call'))
  const [placement, setPlacement] = useState('ALL') // 목록 선택 사항: 전체, 온라인, 오프라인, 방문&선임
  const [contractStateSwitch, setContractStateSwitch] = useState(true) // 계약상태 switch: true(미체결만 보기), false(계약상태 전체보기)
  const { departments } = useDepartmentList(null)
  const { categories } = useCategories()
  const { projectSubTypes } = useProjectSubTypes()
  const { callResercationStatus } = useCallRerservationStatusList()
  const [projectTypeModalOpen, setProjectTypeModalOpen] = useState(false)
  const [contentModalOpen, setContentModalOpen] = useState(false)
  const [cotentInput, setContentInput] = useState(null)
  const [projectTypeSelection, setProjectTypeSelection] = useState(null)
  const [editingRowKey, setEditingRowKey] = useState(null) // 전체 수정시 특정한 row 수정 중인 index
  const navigate = useNavigate()
  const { data: user } = useQuery(getCurrentUser, {
    fetchPolicy: 'network-only'
  })
  const currentUser = _.get(user, ['CurrentUser'])
  const { users } = useUserList()
  const { 
    loading,
    meta,
    filters,
    searchParams,
    onChangeMeta,
    onResetFilterAndGroup,
    getUpdatedSearchParams
  } = useCallRecordFilter()
  const {
    viewMode,
    groupMode,
    disabledTableActionByKey,
    hiddenTableActionByKey,
    recordStatus,
    onChangeViewMode,
    onChangeGroupMode,
    onSelectFilter,
    filterMeta,
    onChangeSelectedRowKeys,
    ...props
  } = useRecordTable()
  /*
    콜 기록 데이터
  */
  const {
    loading: callRecordDataLoading,
    callRecordsData,
    totalCount,
    refetch
  } = useCallRecordList(
    false, // ascending
    null, // key
    searchQuery,
    null, // recordType
    meta.filter.datetimeRange[0],
    meta.filter.datetimeRange[1],
    meta.filter.userIds,
    meta.filter.departmentId[0],
    null, // deleted,
    viewMode === 'list' ? null : groupMode // groupBy
  )
  // 사건구분
  const { projectTypesLoading, projectTypes } = useProjectTypes()
  const [dataCount, setDataCount] = useState(totalCount)
  // const flattenedRecords = _.flatMap(callRecordsData, (item) =>
  //   item.records.map((record) => ({
  //     ...record,
  //     parentName: item.name,
  //     projectType: item.projectType,
  //   }))
  // )
  // 전체/온라인/오프라인/방문&선임에 따라 필터
  const placementChange = (value) => {
    setPlacement(value)
  }
  useEffect(() => {
    let filter = []
    if (placement === 'ALL') {
      filter = callRecordsData
    } else {
      if (viewMode === 'list') {
        filter = _.filter(callRecordsData, ['type', placement])
      } else { // group
        const filterByType = (data, type) =>
          _(data)
            .map(item => ({
              ...item,
              records: _.filter(item.records, { type })
            }))
            .filter(item => item.records.length > 0)
            .value()
        filter = filterByType(callRecordsData, placement)
      }
    }
    const currentFormData = form.getFieldValue('call')
    const updatedCallRecordsData = _.map(filter, (record) => {
      const editingRecord = _.find(editingKeys, (key) => key === record.id)
      return editingRecord ? currentFormData.find(data => data?.id === record?.id) : record
    })
    let currentData = updatedCallRecordsData
    if (contractStateSwitch) { // 계약 상태 Switc에 따른 필터
      if (viewMode === 'list') {
        currentData = _.filter(updatedCallRecordsData, (item) => _.isNull(item.contractRecord))
      } else { // group
        currentData = updatedCallRecordsData.map((group) => ({
          ...group,
          records: _.filter(group.records, (record) => record.contractRecord === null)
        })).filter((group) => group.records.length > 0)
      }
    } else {
      if (viewMode === 'group') { // group
        currentData = updatedCallRecordsData.map((group) => ({
          ...group,
          records: _.filter(group.records, (record) => record.contractRecord !== null)
        })).filter((group) => group.records.length > 0)
      }
    }
    setDataCount(currentData.length)

    const initialValues = {
      call: currentData,
      scratchPad: scratchPadData
    }

    form.setFieldsValue(initialValues)
  }, [callRecordsData])

  // 콜 기록 DataSource에 저장
  useEffect(() => {
    if (!callRecordDataLoading) {
      if (callRecordsData == null) {
        setDataSource([])
      } else {
        setDataSource(callRecordsData)
        setCurrentForm(callRecordsData)
      }
    }
  }, [callRecordDataLoading])

  // 필터: '이번달' 기본 세팅
  useDefaultDateRangeFilter(meta, 'month', (defDateRange) => {
    const newSearchString = getUpdatedSearchParams('filter', 'datetimeRange', defDateRange, searchParams)
    navigateToCallRecord(navigate, recordStatus, newSearchString)
  })

  // 계약 기록 페이지 여부에 따라 계약 페이지 생성 또는 이동
  const handleNavigateContractState = (record) => {
    const contractId = _.get(record, ['contractRecord', 'id'])
    if (contractId === null || contractId === undefined) {
      onOpenModal(record, 'createContractRecord')
    } else {
      navigateToContractRecordDetail(navigate, _.get(record, ['contractRecord']), null, 'view')
    }
  }

  /*
    계약 기록 데이터
  */

  const {
    contractRecordsData
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    null, // from,
    null, // to,
    null, // departmentId,
    null, // deleted,
    null, // caseNumber,
    null, // projectTypeId,
    null, // assignedLawyerId,
    null, // groupby,
    true, // outstanding
    null, // outstandingFrom
    todayDate, // outstandingTo
    null, // closed
    null // projectTypeStr
  )

  const {
    getRecordViewerTableColumns, // 콜 기록
    getRecordViewerTableGroupColumns, // 콜 기록 그룹 항목
    getRecordViewerTableTypeGroupColumns, // 콜 기록 Type 그룹 항목
    editingKeys,
    saveAll,
    editAll
  } = useRecordViewerColumns(
    selectedRowKeys,
    dataSource,
    setDataSource,
    form,
    refetch,
    callRecordsData,
    handleNavigateContractState,
    currentForm,
    setCurrentForm,
    contractRecordsData,
    users,
    projectSubTypes,
    callResercationStatus,
    setProjectTypeModalOpen,
    setEditingRowKey,
    setContentModalOpen,
    projectTypes,
    projectTypesLoading,
    projectTypeSelection
  )

  const {
    blockModalOpen,
    onOkCancelModal,
    onCloseCancelModal
  } = useBlockModal(null, editingKeys.length > 0)

  const {
    onRemoveCallRecord
  } = useCallRecordRemove(refetch)

  const {
    onCreateContractRecord
  } = useContractRecordAddEditRemove()
  const {
    title: confirmModalTitle,
    confirmModalMeta,
    onOpenModal,
    onCloseModal,
    onConfirmModal
  } = useConfirmModal(
    onRemoveCallRecord,
    null,
    null,
    null,
    null,
    null,
    onCreateContractRecord
  )

  const handleRemoveCallRecord = () => {
    onOpenModal(selectedRowKeys, 'deleteCallRecord')
    onChangeSelectedRowKeys([])
  }
  const {
    disabledScratchPadTableActionByKey,
    ...scratchPadProps
  } = useScratchPadTable()

  const handleStatViewer = () => {
    setStatViewer(!statViewer)
  }
  const handleProjectTypeCancel = () => {
    setProjectTypeModalOpen(false)
  }
  const handleContentCancel = () => {
    setContentModalOpen(false)
  }

  const handlProjectTypeOk = () => {
    form.setFieldValue(['call', editingRowKey, 'projectType'], projectTypeSelection)
    setProjectTypeModalOpen(false)
  }
  const handlContentOk = (value) => {
    form.setFieldValue(['call', editingRowKey, 'content'], cotentInput)
    setContentModalOpen(false)
  }

  return (
    <>
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        onOk={onConfirmModal}
        onCancel={onCloseModal}
        // status={'warning'}
      />
      <BlockModal
        open={blockModalOpen}
        title='수정을 취소하시겠습니까?'
        subTitle='변경사항이 저장되지 않습니다.'
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
      <ProjectTypeModal
        pageType='call'
        visible={projectTypeModalOpen}
        onOk={() => handlProjectTypeOk()}
        onCancel={handleProjectTypeCancel}
        dataSource={projectTypesData}
        form={form}
        setProjectTypeSelection={setProjectTypeSelection}
        editingRowKey={editingRowKey}
        setScratchPadList={setScratchPadList}
        projectTypesLoading={projectTypesLoading}
        projectTypes={projectTypes}

      />
      <ContentModal
        pageType='call'
        visible={contentModalOpen}
        onOk={(v) => handlContentOk(v)}
        onCancel={handleContentCancel}
        form={form}
        editingRowKey={editingRowKey}
        setContentInput={setContentInput}
        callRecordDataLoading={callRecordDataLoading}
      />
      <HeaderContentFooter
        boxShadow='none'
        header={(
          <ResponsiveGapContainer>
            <CustomSearchContainer>
              <CustomSearch onSearch={v => setSearchQuery(v)} placeholder='의뢰인명/전화번호를 입력해주세요.' />
            </CustomSearchContainer>
            <CallRecordFilter
              viewMode={viewMode}
              groupMode={groupMode}
              loading={loading}
              meta={meta}
              filters={filters}
              departments={departments}
              onChangeMeta={onChangeMeta}
              onResetFilterAndGroup={onResetFilterAndGroup}
              onSelectFilter={onSelectFilter}
              onChangeGroupMode={onChangeGroupMode}
            />
          </ResponsiveGapContainer>
        )}
        content={(
          <ColumnGapWrapper $gap={10}>
            <CallCustomTable
              data={callRecordsData}
              defaultColumns={getRecordViewerTableColumns}
              groupColumns={getRecordViewerTableGroupColumns}
              typeGroupColumns={getRecordViewerTableTypeGroupColumns}
              total={dataCount}
              pageType='call'
              unit='개'
              disabledTableActionByKey={disabledTableActionByKey}
              hiddenTableActionByKey={hiddenTableActionByKey}
              viewMode={viewMode}
              groupMode={groupMode}
              recordStatus={recordStatus} // 페이지가 즐겨찾기 or 콜 기록
              editingKeys={editingKeys}
              {...props}
              scroll={400}
              saveAll={saveAll}
              editAll={editAll}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              handleStatViewer={handleStatViewer}
              setScratchPadData={setScratchPadData}
              scratchPadList={scratchPadList}
              setScratchPadList={setScratchPadList}
              form={form}
              scratchpadForm={scratchpadForm}
              handleRemoveCallRecord={handleRemoveCallRecord}
              handleNavigateContractState={handleNavigateContractState}
              placementChange={placementChange}
              placement={placement}
              onChangeViewMode={onChangeViewMode}
              onChangeGroupMode={onChangeGroupMode}
              contractStateSwitch={contractStateSwitch}
              setContractStateSwitch={setContractStateSwitch}
              currentUser={currentUser}
              categories={categories}
              projectSubTypes={projectSubTypes}
              onChangeSelectedRowKeys={onChangeSelectedRowKeys}
            />
            {statViewer && <StatTable meta={meta} onChangeMeta={onChangeMeta} handleStatViewer={handleStatViewer} callRecordsData={callRecordsData} />}
            <ScratchPadCustomTable
              scratchPadData={scratchPadData}
              setScratchPadData={setScratchPadData}
              disabledTableActionByKey={disabledScratchPadTableActionByKey}
              scratchPadList={scratchPadList}
              setScratchPadList={setScratchPadList}
              selectedRowKeys={selectedRowKeys}
              refetch={refetch}
              scratchpadForm={scratchpadForm}
              currentUser={currentUser}
              departments={departments}
              users={users}
              categories={categories}
              projectSubTypes={projectSubTypes}
              callResercationStatus={callResercationStatus}
              projectTypes={projectTypes}
              {...scratchPadProps}
            />
          </ColumnGapWrapper>
        )}
      />
    </>
  )
}

export default CallRecordContainer
