import { useEffect, useState } from 'react'
import { Spacer } from '../../../../../common/components/PageHeaderStyles'

import styled from 'styled-components'
import { withSize } from 'react-sizeme'
import { Tag, Typography } from 'antd'
import { MoneyContainer } from '../../../../../common/Theme/style'
import _ from 'lodash'
import { allPaymentCategoryOptions } from '../../../../config'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`
const TimesheetTitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`
const CustomTag = styled(Tag)`
  font-size: 11px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  margin-right: 4px;
`
const isDueDateBeforeTomorrow = (record, remain) => {
  const today = new Date()
  const dueDate = new Date(record.dueDate)
  const overdue = _.lt(dueDate, today) // true: 추심, false: 정상

  if (remain > 0 && overdue) {
    return 'red'
  } else if (remain > 0 && overdue === false) {
    return ' black'
  }
}

const ContractCalendarCellItem = ({
  size: { width },
  color,
  name,
  dueAmount,
  hoveredCaseId,
  minutes,
  id,
  caseNumber,
  caseId,
  category,
  shouldDisplayHHMMFormat,
  onHoverItem = () => {},
  onClickCalendarListItem = () => {},
  onChangeOverflow = () => {},
  ...meta
}) => {
  const sumReceivedAmount = _.isEmpty(meta.deposits)
    ? []
    : _.sumBy(meta.deposits, 'receivedAmount')
  const remain = dueAmount - sumReceivedAmount
  const dueAmountState = isDueDateBeforeTomorrow(meta, remain)
  const shouldloweropacity = hoveredCaseId && hoveredCaseId !== caseId
  const [initTitleWidth, setInitTitleWidth] = useState(0)
  const [initTextWidth, setInitTextWidth] = useState(0)
  // useEffect(() => {
  //   const textelem = document.getElementById(`${id}_text`)
  //   const titleelem = document.getElementById(`${id}_title`)
  //   setInitTitleWidth(titleelem.clientWidth)
  //   setInitTextWidth(textelem.clientWidth)
  // }, [])
  const tagColorMap = {
    착: 'magenta',
    중: 'volcano',
    잔: 'orange',
    상: 'geekblue',
    성: 'purple',
    추: 'green',
    기: 'cyan'
  }

  const categroyTag = (options, value) => {
    const option = _.find(options, { value })
    const currentTag = option ? option.label : null
    const result = _.head(currentTag)

    if (result && tagColorMap[result]) {
      return <CustomTag color={tagColorMap[result]}>{result}</CustomTag>
    }
  }

  const isOverflow = initTitleWidth > width - 20 - initTextWidth
  useEffect(() => {
    onChangeOverflow(isOverflow)
  }, [isOverflow])
  return (
    <Container
      id='cell-item'
      style={{ opacity: shouldloweropacity ? 0.5 : 1 }}
      onClick={() => onClickCalendarListItem({ name, minutes, key: id, caseNumber, caseId, ...meta })}
    >
      <>
        <TimesheetTitleContainer id={`${id}_title`}>
          <div>{categroyTag(allPaymentCategoryOptions, category)}</div>
          <Typography.Text style={{ maxWidth: width - 20 - initTextWidth, minWidth: 20 }} ellipsis={{ tooltip: true }}>
            {name}
          </Typography.Text>
        </TimesheetTitleContainer>
        <Spacer />
        <Typography.Text id={`${id}_text`} style={{ width: 'fit-content' }}>
          <MoneyContainer value={dueAmount === null ? 0 : dueAmount} $color={dueAmountState} />
        </Typography.Text>
      </>
    </Container>
  )
}
export default withSize()(ContractCalendarCellItem)
