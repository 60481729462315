import { useEffect, useState } from 'react'
import { convertToDayjs, parseDateObj, uuidv4 } from '../../../../common/util'
import { Input, InputNumber, Select, Form, Button } from 'antd'
import { allPaymentCategoryOptions, expenseProofMethodOptions, paymentTypeOptions } from '../../../config'
import { CustomDatePicker } from '../../../components/common/CustomDatePicker'
import _ from 'lodash'
import { getLabelByValue } from '../../../components/common/CommonFunction'

import { MoneyContainer } from '../../../../common/Theme/style'
import COLORS from '../../../../common/Theme/colors'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
dayjs.locale('ko')

// 계약 기록 > 입금 내역 목록
export const useDepositForm = (
  readOnly,
  contractDataSource,
  _reasonDataSource,
  extraContractDataSource,
  setExtraContractDataSource,
  paymentRecordDataSource,
  setPaymentRecordDataSource,
  handleDelete = () => {},
  currentUser,
  contractPaymentRecords,
  form
) => {
  const dateFormat = 'YYYY-MM-DD'
  const [paymentCategoryState, setPaymentCategoryState] = useState()
  const [buttonState, setButtonState] = useState(() => {
    return _.reduce(paymentRecordDataSource, (acc, record, index) => {
      if (record.type === 'CARD') {
        acc[index] = true
      } else {
        acc[index] = false
      }
      return acc
    }, [])
  })

  const paymentRecords = _.cloneDeep(paymentRecordDataSource)

  const groupByCategory = _.groupBy(contractPaymentRecords, 'category')

  const paymentOptions = _.flatMap(groupByCategory, (records, category) => {
    return records.map((record, index) => {
      const totalReceived = _.sumBy(record.deposits, 'receivedAmount')
      const isFullyPaid = record.dueAmount - totalReceived === 0
      return {
        value: record.periodicId,
        label: `${_.find(allPaymentCategoryOptions, { value: category }).label}${index + 1}`,
        disabled: isFullyPaid
      }
    })
  })

  const reformatPayment = (data = contractDataSource) => {
    // 계약 금액 목록 항목 조정 (주기 계산)
    if (paymentCategoryState === 'payment') {
      data = contractDataSource
    } else {
      data = extraContractDataSource
    }
    const transformedContracts = _.map(data, contract => {
      let result = []
      let eachPayment = contract.categoryTotal
      if (contract.depositType === 'MULTIPLE') {
        eachPayment = contract.categoryTotal / contract.count
      }
      result = _.map(contract.paymentDeadLine, (deadline, index) => {
        return {
          key: uuidv4(),
          dueAmount: eachPayment,
          totalDeposit: 0,
          dueDate: [deadline],
          status: false,
          category: contract.category
        }
      })
      return {
        ...contract,
        payment: result
      }
    })
    // 계약 금액 > 입금 내역에 따라 총 입금 금액 업데이트
    const updatedContracts = _.forEach(transformedContracts, contract => {
      _.forEach(contract.payment, payment => {
        while (payment.totalDeposit < payment.dueAmount) {
          const matchingRecordIndex = _.findIndex(paymentRecords, { category: contract.category })
          if (matchingRecordIndex === -1) {
            break
          }
          const matchingRecord = paymentRecords[matchingRecordIndex]
          payment.totalDeposit += matchingRecord.receivedAmount
          _.pullAt(paymentRecords, matchingRecordIndex)
          if (payment.totalDeposit >= payment.dueAmount) {
            payment.status = true
            break
          }
        }
      })
    })
    if (paymentCategoryState === 'payment') {
      // setContractDataSource(updatedContracts)
    } else {
      setExtraContractDataSource(updatedContracts)
    }
  }
  useEffect(() => {
    const buttonState = _.reduce(paymentRecordDataSource, (acc, record, index) => {
      if (record.type === 'CARD') {
        acc[index] = true
      } else {
        acc[index] = false
      }
      return acc
    }, [])
    setButtonState(buttonState)
  }, [])

  useEffect(() => {
    reformatPayment() // 계약 항목 입금 항목 생성
  }, [paymentRecordDataSource])

  const checkType = (index) => { // 입금 종류가 카드일 경우 지출증빙방식 비활성화, 그 외 항목들은 활성화
    if (buttonState.length === 0) { // 입금 내역 지출증빙방식 상태 초기값
      const buttonState = _.reduce(paymentRecordDataSource, (acc, record, index) => {
        if (record.type === 'CARD') {
          acc[index] = true
        } else {
          acc[index] = false
        }
        return acc
      }, [])
      setButtonState(buttonState)
    }
    return _.get(buttonState, index, false)
  }
  const handleFieldChange = (key, field, value, index) => {
    if (field === 'category') {
      if (value === '착수금' || value === '중도금' || value === '잔금' || value === '상담료') {
        setPaymentCategoryState('payment')
      } else {
        setPaymentCategoryState('extraPayment')
      }
    }
    if (field === 'received') {
      // const reformat = dayjs(value)
      // value = parseDateObj(dayjs(value))
      // value = value.format('YYYY-MM-DD HH:mm:ss')
      form.setFieldValue(['depositRecordList', index, 'received'], value)
    }
    const updatedDataSource = paymentRecordDataSource.map(item => {
      if (item.key === key) {
        return { ...item, [field]: value }
      }
      return item
    })

    if (field === 'type' && value === 'CARD') { // 입금 종류가 카드가 아닐 경우 지출증빙방식 reset
      form.setFieldValue(['depositRecordList', index, 'expenseProofMethod'], null)

      buttonState[index] = true

      setButtonState(buttonState)
    }
    if (field === 'type' && value !== 'CARD') {
      form.setFieldValue(['depositRecordList', index, 'expenseProofMethod'], null)
      buttonState[index] = false

      setButtonState(buttonState)
    }
    // if (field === 'received') {
    //   form.setFieldValue(['depositRecordList', index, 'received'], value)
    // }
    setPaymentRecordDataSource(updatedDataSource)
  }

  const formItems = [
    {
      key: 'depositRecordList',
      dataIndex: 'depositRecordList',
      cardTitle: '입금 내역',
      value: '추가',
      scrollHeight: '240px',
      columns: [
        {
          title: '금액 종류',
          dataIndex: 'periodicId',
          key: 'periodicId',
          width: 35,
          render: (periodicId, record, index) => {
            return (
              record.created !== undefined
                ? (
                  <div bordered={false} color='processing' style={{ color: `${COLORS.primaryColor}` }}>
                    {getLabelByValue(paymentOptions, record.periodicId)}
                  </div>)
                : (
                  <Form.Item
                    name={['depositRecordList', index, 'periodicId']}
                    rules={[{ required: true, message: '금액 종류를 선택해주세요.' }]}
                  >
                    <Select
                      placeholder='선택'
                      defaultValue={periodicId}
                      options={paymentOptions}
                      onChange={(value) => handleFieldChange(record.id, 'periodicId', value, index)}
                    />
                  </Form.Item>
                  )
            )
          }
        },
        {
          title: '입금 종류',
          dataIndex: 'type',
          key: 'type',
          width: 35,
          render: (type, record, index) => {
            const reformatType = type === '카드' ? 'card' : type === '현금' ? 'cash' : type === '계좌이체' ? 'wire' : 'card'
            return (
              readOnly
                ? (getLabelByValue(paymentTypeOptions, type))
                : (
                  <Form.Item
                    name={['depositRecordList', index, 'type']}
                    rules={[{ required: true, message: '입금종류를 선택해주세요.' }]}
                  >
                    <Select
                      defaultValue={reformatType}
                      options={paymentTypeOptions}
                      onChange={(value) => handleFieldChange(record.id, 'type', value, index)}
                    />
                  </Form.Item>
                  )
            )
          }
        },
        {
          title: '입금 날짜',
          dataIndex: 'received',
          key: 'received',
          width: 32,
          render: (received, record, index) => {
            const format = (received !== null && received !== undefined) ? received?.split(' ')[0] : ''
            return (
              readOnly
                ? (format)
                : (
                  <Form.Item
                    name={['depositRecordList', index, 'received']}
                    rules={[{ required: true, message: '날짜를 선택해주세요.' }]}
                  >
                    <CustomDatePicker
                      date={received?.length > 0 ? dayjs(received) : null}
                      style={{ width: 130 }}
                      onChange={(value) => {
                        // const newDateTime = value ? value.format('YYYY-MM-DD HH:mm:ss') : null
                        handleFieldChange(record.key, 'received', value.format('YYYY-MM-DD HH:mm:ss'))
                      }}
                    />
                  </Form.Item>))
          }
        },
        {
          title: '입금 금액',
          dataIndex: 'receivedAmount',
          key: 'receivedAmount',
          width: 45,
          render: (receivedAmount, record, index) => {
            const remain = record.dueAmount - record.receivedAmount
            return (
              readOnly
                ? (<MoneyContainer value={receivedAmount === null ? 0 : receivedAmount} />)
                : (
                  <Form.Item
                    name={['depositRecordList', index, 'receivedAmount']}
                    rules={[
                      { required: true, message: '금액을 입력해주세요.' },
                      {
                        validator: (_, value) =>
                          value > 0
                            ? Promise.resolve()
                            : Promise.reject(new Error('0보다 큰 숫자를 입력해주세요.'))
                      }
                    ]}
                  >
                    <InputNumber
                      changeOnWheel
                      defaultValue={receivedAmount || 0}
                      placeholder='0'
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      addonAfter='원'
                      min={0}
                      onChange={(value) => handleFieldChange(record.key, 'receivedAmount', value)}
                    />
                  </Form.Item>))
          }
        },
        {
          title: '지출증빙방식',
          dataIndex: 'expenseProofMethod',
          key: 'expenseProofMethod',
          width: 32,
          render: (expenseProofMethod, record, index) => {
            return (
              readOnly
                ? (getLabelByValue(expenseProofMethodOptions, expenseProofMethod))
                : (
                  <Form.Item
                    name={['depositRecordList', index, 'expenseProofMethod']}
                  >
                    <Select
                      placeholder='선택'
                      style={{ width: '120px' }}
                      disabled={checkType(index)}
                      options={expenseProofMethodOptions}
                      onChange={(value) => handleFieldChange(record.id, 'expenseProofMethod', value, index)}
                    />
                  </Form.Item>
                  )
            )
          }
        },
        {
          title: '작성자',
          dataIndex: 'createdBy',
          key: 'createdBy',
          width: 32,
          render: (createdBy) => {
            return (
              readOnly
                ? (<div>{createdBy.name}</div>)
                : (<div>{currentUser?.name}</div>)
            )
          }
        }
      ]
    }
  ]

  if (!readOnly) {
    formItems.find(item => item.key === 'depositRecordList').columns.push({
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      width: 20,
      render: (_, record) => {
        return (
          <Button danger onClick={() => handleDelete(record.id, 'depositRecordList')}>삭제</Button>
        )
      }
    })
  }
  return {
    formItems,
    reformatPayment
  }
}
