import { ColumnGapWrapper, NarrowCustomCard, RowGapWrapper } from '../../../common/Theme/style'
import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import _ from 'lodash'
import { caseDetailCategory } from '../../config'
import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { findMatchingProjectType } from '../common/CommonFunction'

const CardTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
`
const TableCategory = styled.div`
  width: 90px;
  color: ${COLORS.black_65};
`
const StyledEditButton = styled(Button)`
  position: absolute;
  top: 11px;
  right: 10px;
  z-index: 999;
`
export const InfoCard = ({ title, data, readOnly, pageStatus, pageStatusClosed, handleModal = () => {}, projectTypes }) => {
  return (
    <NarrowCustomCard>
      <CardTitle>{title}</CardTitle>
      {(readOnly && pageStatus &&
          pageStatusClosed) && (
            <StyledEditButton
              onClick={() => handleModal('case')}
            >
              <EditOutlined />
            </StyledEditButton>)}
      <ColumnGapWrapper $gap={15}>
        {caseDetailCategory.map(({ key, value, label }) => {
          let content = _.get(data, [value])
          if (key === 'assignedLawyers' && _.isArray(content)) {
            content = content.map((lawyer) => lawyer.name).join(', ')
          } else if (key === 'projectType') {
            const projectTypeId = _.get(data, 'projectType.id', null)
            if (projectTypeId !== null) {
              content = findMatchingProjectType(projectTypeId, projectTypes)
            } else {
              content = ''
            }
          } else if (_.isObject(content)) {
            content = content.name
          }

          return (
            <RowGapWrapper key={key} style={{}}>
              <TableCategory>{label}</TableCategory>
              <div>{content}</div>
            </RowGapWrapper>
          )
        })}
      </ColumnGapWrapper>
    </NarrowCustomCard>
  )
}
