import { Input, Modal, Table } from 'antd'
import styled from 'styled-components'
import { CustomSearchContainer } from '../../../common/Theme/style'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import FlexSearch from 'flexsearch'

const StyledModal = styled(Modal)`
  height: 700px;
`

export const ProjectTypeModal = ({
  pageType,
  visible,
  onOk,
  onCancel,
  dataSource,
  form,
  setProjectTypeSelection,
  editingRowKey,
  setScratchPadList = null,
  projectTypes
}) => {
  const [filteredInfo, setFilteredInfo] = useState({})
  const [results, setResults] = useState(null)
  const [document, setDocument] = useState(null)
  const [currentSelection, setCurrentSelection] = useState(null)
  const [middleCategoryFilters, setMiddleCategoryFilters] = useState([
    { text: '성범죄', value: '성범죄' },
    { text: '경제범죄', value: '경제범죄' },
    { text: '강력범죄', value: '강력범죄' },
    { text: '교통범죄', value: '교통범죄' },
    { text: '마약범죄', value: '마약범죄' },
    { text: '도박', value: '도박' },
    { text: '학교폭력', value: '학교폭력' },
    { text: '일반형사', value: '일반형사' },
    { text: '군형사', value: '군형사' },
    { text: '소년사건', value: '소년사건' },
    { text: '고소,고발', value: '고소,고발' },
    { text: '민사', value: '민사' },
    { text: '가사', value: '가사' },
    { text: '노동,산재,행정', value: '노동,산재,행정' },
    { text: '부동산,건설', value: '부동산,건설' },
    { text: '기업', value: '기업' },
    { text: '의료', value: '의료' }
  ])
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length > 0) {
        const row = selectedRows[0]
        // const concatenatedStr = `${row.대} / ${row.중} / ${row.소}`
        const concatenatedStr = `${row.소}`
        const matchingItem = _.find(projectTypes, { name: concatenatedStr })
        const matchingId = matchingItem ? matchingItem.id : null
        setProjectTypeSelection(matchingItem)
        setCurrentSelection(matchingId)
        // setProjectTypeSelection(concatenatedStr)
        // setCurrentSelection(concatenatedStr)
        if (pageType === 'call') {
          form.setFieldValue(['call', editingRowKey, 'projectType'], matchingItem)
        } else if (pageType === 'scratchpad') {
          form.setFieldValue(['scratchPad', editingRowKey, 'projectType'], matchingItem)
          const newData = form.getFieldValue('scratchPad')
          localStorage.setItem('scratchPad', JSON.stringify(newData))
        } else {
          console.log('filter')
        }
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name
    })
  }
  const onSearch = (value) => {
    if (!document) return
    if (value.trim() === '') {
      setResults(dataSource)
      return
    }
    const ret = document.search(value)

    const ids = _.flatMap(ret, 'result')
    const res = _.map(ids, (id) => _.find(dataSource, { index: id }))

    const uniqueResults = _.uniqBy(res, 'index')

    console.log('Unique search results: ', uniqueResults)
    setResults(uniqueResults)
  }
  const columns = [
    {
      title: '대분류',
      dataIndex: '대',
      key: '대',
      width: 200,
      filters: [
        { text: '형사', value: '형사' },
        { text: '민가사', value: '민가사' },
        { text: '기타', value: '기타' }
      ],
      onFilter: (value, record) => record.대.includes(value),
      filteredValue: filteredInfo.대 || null
      // sorter: (a, b) => a.firstCategory.length - b.firstCategory.length,
      // sortOrder: sortedInfo.columnKey === 'firstCategory' ? sortedInfo.order : null,
      // ellipsis: true
    },
    {
      title: '중분류',
      dataIndex: '중',
      key: '중',
      width: 200,
      filters: middleCategoryFilters,
      onFilter: (value, record) => record.중.includes(value),
      filteredValue: filteredInfo.중 || null
      // sorter: (a, b) => a.age - b.age,
      // sortOrder: sortedInfo.columnKey === 'age' ? sortedInfo.order : null,
      // ellipsis: true
    },
    {
      title: '소분류',
      dataIndex: '소',
      key: '소',
      width: 200
    },
    {
      title: '설명',
      dataIndex: 'note',
      key: 'note'
    }
  ]

  useEffect(() => {
    setDocument(
      new FlexSearch.Document({
        tokenize: 'full',
        document: {
          id: 'index',
          index: ['대', '중', '소', 'note']
        }
      })
    )
  }, [])

  useEffect(() => {
    if (!document) return
    dataSource.map((doc) => {
      document.add(doc)
    })
  }, [document])

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters)

    // Update middle category filters based on '대분류'
    const selectedMainCategory = filters['대'] ? filters['대'][0] : null

    if (selectedMainCategory === '형사') {
      setMiddleCategoryFilters([
        { text: '성범죄', value: '성범죄' },
        { text: '경제범죄', value: '경제범죄' },
        { text: '강력범죄', value: '강력범죄' },
        { text: '교통범죄', value: '교통범죄' },
        { text: '마약범죄', value: '마약범죄' },
        { text: '도박', value: '도박' },
        { text: '학교폭력', value: '학교폭력' },
        { text: '일반형사', value: '일반형사' },
        { text: '군형사', value: '군형사' },
        { text: '소년사건', value: '소년사건' },
        { text: '고소,고발', value: '고소,고발' }
      ])
    } else if (selectedMainCategory === '민가사') {
      setMiddleCategoryFilters([
        { text: '민사', value: '민사' },
        { text: '가사', value: '가사' }
      ])
    } else if (selectedMainCategory === '기타') {
      setMiddleCategoryFilters([
        { text: '노동,산재,행정', value: '노동,산재,행정' },
        { text: '부동산,건설', value: '부동산,건설' },
        { text: '기업', value: '기업' },
        { text: '의료', value: '의료' }
      ])
    } else {
      setMiddleCategoryFilters([
        { text: '성범죄', value: '성범죄' },
        { text: '경제범죄', value: '경제범죄' },
        { text: '강력범죄', value: '강력범죄' },
        { text: '교통범죄', value: '교통범죄' },
        { text: '마약범죄', value: '마약범죄' },
        { text: '도박', value: '도박' },
        { text: '학교폭력', value: '학교폭력' },
        { text: '일반형사', value: '일반형사' },
        { text: '군형사', value: '군형사' },
        { text: '소년사건', value: '소년사건' },
        { text: '고소,고발', value: '고소,고발' },
        { text: '민사', value: '민사' },
        { text: '가사', value: '가사' },
        { text: '노동,산재,행정', value: '노동,산재,행정' },
        { text: '부동산,건설', value: '부동산,건설' },
        { text: '기업', value: '기업' },
        { text: '의료', value: '의료' }
      ])
    }
  }
  return (
    <StyledModal
      title='사건분류'
      open={visible}
      onOk={() => onOk(currentSelection)}
      onCancel={onCancel}
      width={1000}
      height={800}
      okText='확인'
      cancelText='취소'
      zIndex={1100}
    >
      <CustomSearchContainer>
        <Input.Search
          placeholder='검색어를 입력해주세요.'
          onSearch={onSearch}
          style={{ marginBottom: 20, marginTop: 20 }}
          allowClear
        />
      </CustomSearchContainer>
      <Table
        columns={columns}
        dataSource={results || dataSource}
        onChange={handleChange}
        rowKey='index'
        pagination={false}
        scroll={{ y: 500 }}
        rowSelection={{
          type: 'radio',
          ...rowSelection
        }}
      />
    </StyledModal>
  )
}
