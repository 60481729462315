import { gql } from '@apollo/client'

// 콜 기록 페이지 쿼리
export const getCallRecordList = gql`
  query GetCallRecordList($sortBy: SortBy, $filter: GetCallRecordListFilter, $groupBy: String) {
  GetCallRecordList(sortBy: $sortBy, filter: $filter, groupBy: $groupBy) {
    totalCount
    ... on GetCallRecordListResponse {
      totalCount
      records {
        id
        type
        callCount
        created
        updated
        createdBy {
          id
          name
        }
        updatedBy {
          id
          name
        }
        name
        gender
        age
        phone
        email
        address
        primaryAuthor {
          id
          name
        }
        secondaryAuthor {
          id
          name
        }
        inputDatetime
        projectTypeStr
        projectType {
          id
          name
        }
        projectSubType {
          id
          name
        }
        department {
          id
          name
        }
        via
        expectedCaseName
        manager {
          id
          name
        }
        amount
        jurisdiction
        caseNumber
        content
        callType
        visitReserved
        reservationStatus {
          id
          name
          order
        }
        reservationCancelReason
        visited
        visitor
        visitDepartment {
          id
          name
        }
        counselLawyers {
          id
          name
        }
        counselEmployees {
          id
          name
          departments {
            id
            name
          }
          group {
            id
            name
          }
        }
        nVisit
        nRevisit
        contractor
        assigned
        nAssign
        assignType {
          id
          name
        }
        cancelled
        cancelReason
        starred
        contractRecord {
          id
          deleted
          closed
        }
      }
    }
    ... on GetCallRecordGroupedListResponse {
      totalCount
      groups {
        name
        projectTypeStr
        projectType {
          id
          name
        }
        records {
          id
          type
          callCount
          created
          updated
          createdBy {
            id
            name
          }
          updatedBy {
            id
            name
          }
          name
          gender
          age
          phone
          email
          address
          primaryAuthor {
            id
            name
          }
          secondaryAuthor {
            id
            name
          }
          inputDatetime
          projectType {
            id
            name
          }
          projectSubType {
            id
            name
          }
          department {
            id
            name
          }
          via
          expectedCaseName
          manager {
            id
            name
          }
          amount
          jurisdiction
          caseNumber
          content
          callType
          visitReserved
          reservationStatus {
            id
            name
            order
          }
          reservationCancelReason
          visited
          visitor
          visitDepartment {
            id
            name
          }
          counselLawyers {
            id
            name
          }
          counselEmployees {
          id
          name
          departments {
            id
            name
          }
          group {
            id
            name
          }
        }
          nVisit
          nRevisit
          contractor
          assigned
          nAssign
          assignType {
            id
            name
          }
          cancelled
          cancelReason
          starred
          contractRecord {
            id
            closed
          }
        }
      }
    }
  }
}
`

export const getCallRecord = gql`
  query GetCallRecord($getCallRecordId: ID!) {
    GetCallRecord(id: $getCallRecordId) {
      id
      type
      callCount
      created
      updated
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
      name
      gender
      age
      phone
      email
      address
      primaryAuthor {
        id
        name
      }
      secondaryAuthor {
        id
        name
      }
      inputDatetime
      projectTypeStr
      projectType {
        id
        name
      }
      projectSubType {
        id
        name
      }
      department {
        id
        name
      }
      reservationStatus {
        id
        name
        order
      }
      via
      expectedCaseName
      manager {
        id
        name
      }
      amount
      jurisdiction
      caseNumber
      content
      callType
      visitReserved
      reservationCancelReason
      visited
      visitor
      counselLawyers {
        id
        name
      }
      counselEmployees {
        id
        name
      }
      nVisit
      nRevisit
      contractor
      assigned
      nAssign
      assignType {
        id
        name
      }
      cancelled
      cancelReason
      starred
      contractRecord {
        id
        deleted
      }
      visitDepartment {
        id
        name
      }
    }
  }
`

export const getContractRecord = gql`
  query GetContractRecord($getContractRecordId: ID!) {
    GetContractRecord(id: $getContractRecordId) {
      id
      created
      createdBy {
        id
        name
      }
      updated
      updatedBy {
        id
        name
      }
      closed
      closedBy {
        id
        name
      }
      deleted
      deletedBy {
        id
        name
      }
      callRecord {
        id
        type
        name
        phone
        email
        address
        department {
          id
          name
        }
        manager {
          id
          name
        }
        assigned
        contractor
        caseNumber
        jurisdiction
        expectedCaseName
        projectType {
          id
          name
        }
      }
      project {
        id
      }
      paymentRecords {
        id
        createdBy {
          id
          name
        }
        updatedBy {
          id
          name
        }
        created
        updated
        dueDate
        dueAmount
        category
        periodic
        periodicId
        condition
        deposits {
          id
          category
          received
          receivedAmount
          type
          expenseProofMethod
          created
          createdBy {
            id
            name
          }
        }
      }
      caseNumber
      caseName
      projectTypeStr
      projectType {
        id
        name
      }
      department {
        id
        name
      }
      assignedLawyers {
        id
        name
      }
      name
      representer
      phone
      address
      email
      opponent
      jurisdiction
      paymentComments {
        id
        contract {
          id
        }
        created
        createdBy {
          id
          name
        }
        content
      }
      registrationNumber
    }
  }
`

export const getContractRecordListIds = gql`
  query GetContractRecordListResponse {
    GetContractRecordList {
      ... on GetContractRecordListResponse {
        totalCount
        records {
          id
        }
        totalDueAmount
        totalReceived
      }
      ... on GetContractRecordGroupedListResponse {
        totalCount
        groups {
          records {
            id
          }
        }
        totalDueAmount
        totalReceived
      }
    }
  }
`
export const getUserList = gql`
  query GetUserList($sortBy: SortBy!, $filter: UserListFilter, $offset: Int, $limit: Int) {
    GetUserList(sortBy: $sortBy, filter: $filter, offset: $offset, limit: $limit) {
      data {
        id
        name
        departments {
          id
          name
        }
        group {
          id
          name
        }
      }
    }
  }
`

export const getCategories = gql`
  query GetProjectTypeList($filter: ProjectTypeFilter) {
    GetProjectTypeList(filter: $filter) {
      id
      name
      order
    }
  }
`

export const getProjectSubTypeList = gql`
  query GetProjectSubTypeList($filter: ProjectSubTypeFilter) {
    GetProjectSubTypeList(filter: $filter) {
      id
      name
      type {
        id
        name
      }
    }
  }
`

export const getProjectTypeDevList = gql`
  query GetProjectTypeDevList {
    GetProjectTypeDevList {
      id
      name
      parents
      note
    }
  }
`
