import { useState } from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { MoneyContainer } from '../../../../common/Theme/style'

dayjs.locale('ko')

export const useOverdueViewerColumns = () => {
  const [editingKeys, setEditingKeys] = useState([])

  const getOverdueViewerTableGroupColumns = [
    {
      title: '부서',
      dataIndex: 'department',
      key: 'department',
      sort: true,
      width: 100,
      render: (department) => {
        let name
        if (department === null) {
          name = '미배치'
        } else if (department.name === null) {
          name = '미배치'
        } else {
          name = department.name
        }
        return (
          <div>{name}</div>
        )
      }
    },
    {
      title: '총 계약 금액',
      dataIndex: 'totalDueAmount',
      key: 'totalDueAmount',
      sort: true,
      width: 100,
      render: (totalDueAmount) => {
        return (
          <MoneyContainer value={totalDueAmount === null ? 0 : totalDueAmount} />
        )
      }
    },
    {
      title: '개수',
      dataIndex: 'totalCount',
      key: 'totalCount',
      sort: true,
      width: 110
    }
  ]

  const getOverdueViewerTableGroupTypeColumns = [
    {
      title: '사건구분',
      dataIndex: 'projectType',
      key: 'projectType',
      width: 100,
      render: (projectType, record) => {
        let name = '미배치'
        if (projectType !== 'null') {
          name = projectType?.name
          if (name !== '형사' && name !== '민가사') {
            name = '미배치'
          }
        }
        return (
          <div>{name}</div>
        )
      }
    },
    {
      title: '총 계약 금액',
      dataIndex: 'totalDueAmount',
      key: 'totalDueAmount',
      sort: true,
      width: 100,
      render: (totalDueAmount) => {
        return (
          <MoneyContainer value={totalDueAmount === null ? 0 : totalDueAmount} />
        )
      }
    },
    {
      title: '개수',
      dataIndex: 'totalCount',
      key: 'totalCount',
      sort: true,
      width: 110
    }
  ]

  const saveAll = () => {
    try {
      _.remove(editingKeys)
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const editAll = (data) => {
    try {
      setEditingKeys(_.map(data, 'key'))
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }
  return {
    getOverdueViewerTableGroupColumns,
    getOverdueViewerTableGroupTypeColumns,
    editingKeys,
    saveAll,
    editAll
  }
}
