import { useEffect, useState } from 'react'
import { uuidv4 } from '../../../common/util'
import { TableActions } from './TableActions'
import { RowGapWrapper } from '../../../common/Theme/style'
import { HeaderGroup, Spacer } from '../../../common/components/PageHeaderStyles'
import { useCallRecordSaveEdit } from '../../hooks/callRecord/useCallRecordSaveEdit'
import { useRecordAdderColumns } from '../../hooks/callRecord/useScratchPadTable/useRecordAdderColumns'

import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Table, Form } from 'antd'
import _ from 'lodash'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { ProjectTypeModal } from '../recordCustomTable/ProjectTypeModal'
import { projectTypesData } from '../../../mockdata'
import { ContentModal } from '../recordCustomTable/ContentModal'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-table-wrapper .ant-table-cell-fix-left, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table-cell-fix-right {
    z-index: 1;
  }
  .ant-table-row {
    cursor: pointer;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`

export const ScratchPadCustomTable = ({
  scratchPadData = [],
  scratchPadList,
  setScratchPadList,
  refetch,
  scratchpadForm,
  currentUser,
  departments,
  users,
  categories,
  projectSubTypes,
  callResercationStatus,
  projectTypes
}) => {
  const [dataSource, setDataSource] = useState([]) // 콜 기록 데이터
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [projectTypeModalOpen, setProjectTypeModalOpen] = useState(false)
  const [projectTypeSelection, setProjectTypeSelection] = useState(null)
  const [editingRowKey, setEditingRowKey] = useState(null) // 특정한 row 수정 중인 index
  const [contentModalOpen, setContentModalOpen] = useState(false)
  const [cotentInput, setContentInput] = useState(null)
  const {
    onSaveCallRecord
  } = useCallRecordSaveEdit(scratchpadForm, setScratchPadList, refetch)

  const saveScratchPadData = (key) => {
    const currentRows = scratchpadForm.getFieldValue('scratchPad') || []
    if (key !== undefined) {
      const selectedRow = currentRows.filter((item) => key.includes(item.key))
      onSaveCallRecord(selectedRow)
    }
  }
  const handleProjectTypeCancel = () => {
    setProjectTypeModalOpen(false)
  }
  const {
    getRecordAdderTableColumns
  } = useRecordAdderColumns(
    scratchpadForm,
    scratchPadData,
    saveScratchPadData,
    departments,
    users,
    categories,
    projectSubTypes,
    callResercationStatus,
    setProjectTypeModalOpen,
    setEditingRowKey,
    projectTypeSelection,
    setContentModalOpen,
    projectTypes
  )
  const initialData = JSON.parse(localStorage.getItem('scratchPad')) || null
  useEffect(() => {
    setScratchPadList(initialData)
    setDataSource(initialData)
    scratchpadForm.setFieldsValue({ scratchPad: initialData })
  }, [])

  // 콜 기록 DataSource에 저장
  useEffect(() => {
    setDataSource(initialData)
  }, [scratchPadList])

  const handleAdd = () => {
    const newId = uuidv4()
    const newData = {
      key: newId,
      id: newId,
      type: null,
      created: dayjs(new Date()),
      inputDate: null,
      inputTime: null,
      callType: null,
      department: null,
      primaryAuthor: null,
      secondaryAuthor: null,
      projectType: null,
      projectTypeStr: null,
      projectSubType: null,
      name: null,
      gender: null,
      age: null,
      phone: null,
      address: null,
      via: null,
      expectedCaseName: null,
      jurisdiction: null,
      caseNumber: null,
      manager: null,
      amount: null,
      content: null,
      visitDepartment: null,
      visitReservedDate: null,
      visitReservedTime: null,
      reservationStatus: null,
      reservationCancelReason: null,
      callCount: null,
      visitedDate: null,
      visitedTime: null,
      visitor: null,
      counselEmployees: null,
      createdBy: currentUser?.name, // 현재 사용자가 입력자로 자동 입력됨
      nVisit: null,
      nRevisit: null,
      contractor: null,
      assigned: null,
      nAssign: null,
      assignType: null,
      cancelled: null,
      cancelReason: null,
      contractState: null
    }
    const storedData = JSON.parse(localStorage.getItem('scratchPad') || '[]') // 현재 scartchpad에 저장 되어있는 데이터
    scratchpadForm.setFieldsValue({ scratchPad: [...storedData, newData] })
    localStorage.setItem('scratchPad', JSON.stringify([...storedData, newData]))
    setScratchPadList(scratchpadForm.getFieldValue('scratchPad'))
  }
  const handleSave = (row) => {
    const newData = [...scratchPadData]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row
    })
  }
  const columns = getRecordAdderTableColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataindex: col.dataIndex,
        title: col.title,
        handleSave
      })
    }
  })
  const handleRowSelectionChange = (selectedRowKeys, refetch = () => {}) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  // Scratchpad 바뀐 데이터 localstorage에 저장
  const handleFormChange = (changedValues, allValues) => {
    const currentRows = scratchpadForm.getFieldValue('scratchPad') || []
    const updatedRows = currentRows.map((row, index) => {
      const rowKey = index
      if (_.keys(changedValues.scratchPad[rowKey]).find(k => k === 'inputDate') === 'inputDate') {
        _.set(changedValues.scratchPad[rowKey], 'inputDate', changedValues.scratchPad[rowKey].inputDate.format('YYYY-MM-DD'))
      } else if (_.keys(changedValues.scratchPad[rowKey]).find(k => k === 'inputTime') === 'inputTime') {
        _.set(changedValues.scratchPad[rowKey], 'inputTime', changedValues.scratchPad[rowKey].inputTime?.format('HH:mm:ss'))
      }
      if (changedValues.scratchPad && changedValues.scratchPad[rowKey]) {
        return {
          ...row,
          ...changedValues.scratchPad[rowKey]
        }
      }
      return row
    })
    scratchpadForm.setFieldsValue({ scratchPad: updatedRows })
    localStorage.setItem('scratchPad', JSON.stringify(updatedRows))
  }
  const handlProjectTypeOk = () => {
    const updatedScratchPad = scratchpadForm.getFieldValue('scratchPad') || []
    const updatedRowIndex = updatedScratchPad.findIndex(
      (row) => row.key === editingRowKey
    )
    if (updatedRowIndex > -1) {
      updatedScratchPad[updatedRowIndex].projectType = projectTypeSelection
    }
    scratchpadForm.setFieldsValue({ scratchPad: updatedScratchPad })
    setScratchPadList(updatedScratchPad)
    setProjectTypeModalOpen(false)
  }

  const handlContentOk = () => {
    const updatedScratchPad = scratchpadForm.getFieldValue('scratchPad') || []
    const scratchPadList = updatedScratchPad.map((row, index) => ({
      ...row,
      index
    }))
    const updatedRowIndex = scratchPadList.findIndex(
      (row) => row.index === editingRowKey
    )
    if (updatedRowIndex > -1) {
      scratchPadList[updatedRowIndex].content = cotentInput
    }
    scratchpadForm.setFieldsValue({ scratchPad: scratchPadList })
    setScratchPadList(scratchPadList)
    localStorage.setItem('scratchPad', JSON.stringify(scratchPadList))
    setContentModalOpen(false)
  }
  const handleContentCancel = () => {
    setContentModalOpen(false)
  }
  return (
    <>
      <ProjectTypeModal
        pageType='scratchpad'
        visible={projectTypeModalOpen}
        onOk={() => handlProjectTypeOk()}
        onCancel={handleProjectTypeCancel}
        dataSource={projectTypesData}
        form={scratchpadForm}
        setProjectTypeSelection={setProjectTypeSelection}
        editingRowKey={editingRowKey}
        projectTypes={projectTypes}
      />
      <ContentModal
        pageType='scratchpad'
        visible={contentModalOpen}
        onOk={(v) => handlContentOk(v)}
        onCancel={handleContentCancel}
        form={scratchpadForm}
        editingRowKey={editingRowKey}
        setContentInput={setContentInput}
        dataSource={dataSource}
      />
      {/* <Button onClick={() => { console.log('call', scratchpadForm.getFieldValue()) }}>DEBUG!</Button> */}
      <Container>
        {scratchPadList?.length < 1
          ? (
            <ButtonContainer>
              <Button
                type='primary'
                onClick={handleAdd}
              >
                <PlusOutlined />
                상담등록
              </Button>
            </ButtonContainer>)
          : (
            <Form
              form={scratchpadForm}
              onValuesChange={handleFormChange}
            >
              <HeaderGroup>
                <RowGapWrapper $gap={15}>
                  <Button
                    type='primary'
                    onClick={handleAdd}
                    style={{
                      marginBottom: 16
                    }}
                  >
                    <PlusOutlined />
                    상담등록
                  </Button>
                  <TableActions
                    dataSource={scratchPadData}
                    selectedRowKeys={selectedRowKeys}
                    setScratchPadList={setScratchPadList}
                    form={scratchpadForm}
                  />
                </RowGapWrapper>
                <Spacer />
              </HeaderGroup>
              <Table
                columns={columns}
                dataSource={scratchPadList}
                pagination={false}
                size='middle'
                rowSelection={{
                  type: 'checkbox',
                  onChange: (selectedRowKeys) => {
                    handleRowSelectionChange(selectedRowKeys)
                  }
                }}
                scroll={{
                  y: 600
                }}
              />
            </Form>)}
      </Container>
    </>
  )
}
