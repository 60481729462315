import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { navigateToContractRecordDetail } from '../../../cacheAndNavigation/contractRecordDetail/navigation'
import { useBlockModal } from '../../../../timeCharge/hooks/common/useBlockModal'
import _ from 'lodash'
import { useContractPaymentsAddEditRemove } from '../useContractPaymentsAddEditRemove'
import { useCommentAddRemove } from '../useCommentAddRemove'
import { useDepositAddEditRemove } from '../useDepositAddEditRemove'

export const useSavingContractRecordDetailForm = (
  contractRecordData,
  formStatus,
  newContractIds = [],
  deletedContractIds = [],
  refetch = () => {},
  setNewContractIds,
  setDeletedContractIds,
  newReasonIds,
  setNewReasonIds,
  removedReasonIds,
  setRemovedReasonIds,
  newDepositIds,
  setNewDepositIds,
  removedDepositIds,
  setRemovedDepositIds,
  pageDelete = false,
  setEditMode,
  setContractDataSource,
  contractDataSource
) => {
  const [readOnly, setReadOnly] = useState(true)
  const navigate = useNavigate()
  const onEdit = () => {
    setReadOnly(!readOnly)
    setEditMode(true)
    navigateToContractRecordDetail(navigate, contractRecordData, null, 'editing')
  }
  const totalReceivedAmount = (data) => {
    if (data !== undefined && data.length > 0) {
      return (
        data.reduce(
          (sum, deposit) => sum + (deposit.receivedAmount || 0),
          0
        )
      )
    } else {
      return 0
    }
  }
  const formattedData = (value) => {
    return (
      _.map(_.groupBy(value, (record) => _.trim(record.periodicId)), (records, periodicId) => ({
        key: periodicId,
        id: records[0].id,
        periodicId,
        totalDueAmount: _.sumBy(records, 'dueAmount'),
        totalReceivedAmount: totalReceivedAmount(records[0].deposits),
        category: records[0].category,
        periodic: records[0].periodic,
        dueDate: records[0].dueDate,
        dueAmount: records[0].dueAmount,
        condition: records[0].condition,
        payments: _.map(records, (record) => ({
          key: record.id,
          dueDate: record.dueDate,
          dueAmount: record.dueAmount,
          deposits: record.deposits,
          category: record.category,
          condition: record.condition
        }))
      })))
  }


  // const onCancel = (form, contractType) => {
  //   // 새로 추가된 계약서 제거
  //   let newItemRemoved = null
  //   setReadOnly(!readOnly)
  //   setEditMode(false)
  //   navigateToContractRecordDetail(navigate, payment, null, 'view')
  //   if (contractType === 'contract') {
  //     newItemRemoved = _.omit(initialData, newContractIds)
  //     setContractDataSource(newItemRemoved)
  //   } else if (contractType === 'extraContract') {
  //     newItemRemoved = _.omit(initialData, newContractIds)
  //     setContractDataSource(newItemRemoved)
  //   } else if (contractType === 'commentList') {
  //     newItemRemoved = initialData
  //     setReasonDataSource(newItemRemoved)
  //   } else { // deposit
  //     newItemRemoved = initialData
  //     setDepositDataSource(newItemRemoved)
  //   }
  //   // form.resetFields()
  //   const updatedFormValues = {
  //     ...currentFormValues,
  //     [contractType]: newItemRemoved
  //   }
  //   form.setFieldsValue(updatedFormValues)
  //   setEditMode(false)
  // }


  const onCancel = (form, contractType) => {
    const paymentRecords = _.get(contractRecordData, 'paymentRecords', [])
    const paymentComments = _.get(contractRecordData, 'paymentComments', [])
    // setCommentContractData(paymentComments)
    const restructureContracts = (contracts) => {
      return contracts.reduce((acc, contract) => {
        acc[contract.periodicId] = contract
        return acc
      }, {})
    }
    /* 계약 총 금액 */
    // setContractTotal(_.sumBy(paymentRecords, 'dueAmount'))
    /* 총 계약 내역 계약 금액과 추가 계약 조건으로 분할 */
    const contracts = _.filter(paymentRecords, record =>
      ['DEPOSIT', 'INTERIM', 'BALANCE', 'CONSULT'].includes(record.category)
    )
    const contractsData = restructureContracts(formattedData(contracts))
    const extraContracts = _.filter(paymentRecords, record =>
      ['CONTINGENT', 'EXTRA', 'ETC'].includes(record.category)
    )
    const extracontractData = restructureContracts(formattedData(extraContracts))
    /* 입금 내역 */
    const depositsArray = _.flatMap(_.filter(paymentRecords, record => record.deposits.length > 0), 'deposits')
    const initialValues = {
      contract: contractsData,
      extraContract: extracontractData,
      commentList: paymentComments,
      depositRecordList: depositsArray
    }

    if (contractType === 'contract') {
      const newItemRemoved = _.omit(initialValues, newContractIds)
      setContractDataSource(newItemRemoved)
    } 
    // else if (contractType === 'extraContract') {
    //   newItemRemoved = _.omit(initialData, newContractIds)
    //   setContractDataSource(newItemRemoved)
    // } else if (contractType === 'commentList') {
    //   newItemRemoved = initialData
    //   setReasonDataSource(newItemRemoved)
    // } else { // deposit
    //   newItemRemoved = initialData
    //   setDepositDataSource(newItemRemoved)
    // }
    form.resetFields()
    form.setFieldsValue(initialValues)
    setEditMode(false)
    setReadOnly(!readOnly)
    navigateToContractRecordDetail(navigate, contractRecordData, null, 'view')
  }
  const {
    onCreateContractPayments,
    onRemoveContractPayment,
    onEditContractPayment
  } = useContractPaymentsAddEditRemove(refetch)

  const {
    onAddComment,
    onEditComment,
    onRemoveComment
  } = useCommentAddRemove(refetch)

  const {
    onAddDepositRecord,
    onEditDepositRecord,
    onRemoveDepositRecord
  } = useDepositAddEditRemove(refetch)

  const checkPayment = (contracts, depositRecordList,) => {
    const sumCurrentInput = _.mapValues(contracts, (contract) => {
      const totalReceived = _.sumBy(
        _.filter(depositRecordList, { id: contract.key }),
        'receivedAmount'
      )
      return { ...contract, totalReceived }
    })
    return (_.filter(sumCurrentInput, (item) => {
      const remain = item.totalDueAmount - item.totalReceivedAmount;
      return remain < 0
    }))
  }
  const onSave = async (form, id, type) => {
    const values = form.getFieldValue()
    const combinedContracts = _.merge({}, values.contract, values.extraContract)
    const contracts = values.contract
    const depositRecordList = values.depositRecordList
    const result = checkPayment(contracts, depositRecordList)
    // if (!_.isEmpty(result)) {
    //   Modal.warning({
    //     title: '경고',
    //     content: '입금 금액이 초과된 항목이 있습니다.',
    //     onOk: () => {
    //       // Reset the problematic entries
    //       result.forEach((item) => {
    //         item.totalReceived = item.totalDueAmount; // Reset the totalReceived to match the due amount
    //       })
    //       console.log('Result reset:', result); // For debugging
    //     }
    //   });
    //   return; // Stop saving to avoid further issues
    // }
    const commentList = values.commentList
    const depositList = values.depositRecordList
    const formattedDataArray = _.chain(combinedContracts)
      .filter((item) => newContractIds.includes(item.periodicId))
      .map((item) => {
        if (item.periodic !== null) { // 주기 입금
          return {
            contractId: id,
            periodic: item.periodic,
            periodicId: item.periodicId,
            payments: [
              {
                category: item.category,
                dueAmount: item.dueAmount,
                dueDate: item.dueDate,
                expenseProofMethod: null,
                // condition: item.condition,
                type: null
              }
            ]
            // payments: _.map(item.payments, (contractRecordData) => ({
            //   category: item.category,
            //   dueAmount: contractRecordData.dueAmount,
            //   dueDate: contractRecordData.dueDate,
            //   expenseProofMethod: null,
            //   type: null
            // }))
          }
        } else { // 단일 입금
          return {
            contractId: id,
            periodic: item.periodic,
            periodicId: item.periodicId,
            payments: [
              {
                category: item.category,
                dueAmount: item.dueAmount,
                dueDate: item.dueDate,
                expenseProofMethod: null,
                condition: item.condition,
                type: null
              }
            ]
          }
        }
      })
      .value()
    if (type === 'contract' || type === 'extraContract') {
      try {
        setReadOnly(!readOnly)
        const editedFormatDataArray = _.chain(combinedContracts)
          .filter((item) => !newContractIds.includes(item.periodicId))
          .map((item) => {
            return {
              periodicId: item.periodicId,
              input: {
                category: item.category,
                dueAmount: item.dueAmount,
                dueDate: item.dueDate,
                expenseProofMethod: null,
                condition: item.condition,
                type: null
              }
            }
          })
          .value()
        if (newContractIds.length > 0) {
          _.forEach(formattedDataArray, onCreateContractPayments)
        }
        if (deletedContractIds.length > 0) {
          _.forEach(deletedContractIds, onRemoveContractPayment)
        }
        _.forEach(editedFormatDataArray, onEditContractPayment)
        setNewContractIds([])
        setDeletedContractIds([])
        form.resetFields()
      } catch (error) {
      }
    } else if (type === 'comment') {
      try {
        const addCommentFormatDataArray = _.chain(commentList)
          .filter((item) => newReasonIds.includes(item.id))
          .map((item) => {
            return {
              contractId: id,
              content: item.content
            }
          })
          .value()

        const editCommentFormatDataArray = _.chain(commentList)
          .filter((item) => !newReasonIds.includes(item.id))
          .map((item) => {
            return {
              editCommentId: item.id,
              content: item.content
            }
          })
          .value()

        if (newReasonIds.length > 0) {
          _.forEach(addCommentFormatDataArray, onAddComment)
        }
        if (removedReasonIds.length > 0) {
          _.forEach(removedReasonIds, onRemoveComment)
        }
        _.forEach(editCommentFormatDataArray, onEditComment)
        setReadOnly(!readOnly)
        setNewReasonIds([])
        setRemovedReasonIds([])
      } catch (error) {
      }
    } else if (type === 'depositRecordList') {
      try {
        const addDepositFormatDataArray = _.chain(depositList)
          .filter((item) => newDepositIds.includes(item.id))
          .map((item) => {
            const matchedContract = _.find(combinedContracts, { periodicId: item.periodicId })
            return {
              id: matchedContract.id,
              input: {
                received: item.received,
                receivedAmount: item.receivedAmount,
                type: item.type,
                expenseProofMethod: item.expenseProofMethod
              }
            }
          })
          .value()
        const editedDepositFormatDataArray = _.chain(depositList)
          .filter((item) => !newDepositIds.includes(item.id))
          .map((item) => {
            return {
              id: item.id,
              input: {
                received: item.received,
                receivedAmount: item.receivedAmount,
                type: item.type,
                expenseProofMethod: item.expenseProofMethod
              }
            }
          })
          .value()

        if (newDepositIds.length > 0) {
          _.forEach(addDepositFormatDataArray, onAddDepositRecord)
        }
        if (removedDepositIds.length > 0) {
          _.forEach(removedDepositIds, onRemoveDepositRecord)
        }
        _.forEach(editedDepositFormatDataArray, onEditDepositRecord)
        setReadOnly(!readOnly)
        setNewDepositIds([])
        setRemovedDepositIds([])
      } catch (error) {
      }
    }
    setEditMode(false)
    form.resetFields()
  }

  const onDeleteContractPayment = (form, contractType, periodicId) => {
    const currentFormValues = form.getFieldsValue()
    const filterDataByPeriodicId = (data, periodicId) => _.omit(data, [periodicId])
    if (contractType === 'contract') {
      setContractDataSource(filterDataByPeriodicId(contractDataSource, periodicId))
    } else if (contractType === 'extraContract') {
      setContractDataSource(filterDataByPeriodicId(contractDataSource, periodicId))
    }
    if (_.includes(newContractIds, periodicId)) {
      setNewContractIds((current) => _.without(current, periodicId))
    } else {
      setDeletedContractIds((current) => [...current, periodicId])
    }
    form.resetFields()
    const updatedFormValues = {
      ...currentFormValues,
      [contractType]: filterDataByPeriodicId(contractDataSource, periodicId)
    }
    form.setFieldsValue(updatedFormValues)
    // form.setFieldsValue(filterDataByPeriodicId(contractDataSource, periodicId))
  }

  const {
    blockModalOpen,
    onOkCancelModal,
    onCloseCancelModal
  } = useBlockModal(null, formStatus === 'editing' && !pageDelete)
  return {
    cancelModalOpen: blockModalOpen,
    onCloseCancelModal,
    onOkCancelModal,
    onEdit,
    onCancel,
    onSave,
    onDeleteContractPayment,
    readOnly
  }
}
