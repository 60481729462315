import { Button, Form, Input, Select } from 'antd'
import { useState } from 'react'
import { useCategories } from '../../callRecord/useCategories'
import _ from 'lodash'
import { useDepartmentList } from '../../common/filter/useDepartmentList'
import { useUserList } from '../../../../common/hooks/apollo/useUserList'
import styled from 'styled-components'
import { findMatchingProjectType } from '../../../components/common/CommonFunction'
import { projectTypes } from '../../../../mockdata'

const StyledButton = styled(Button)`
  :where(.css-dev-only-do-not-override-4oxdqr).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #000;
    border-color:#000;
  }
`
const EllipsisContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

// 계약 기록 > 콜 고객 정보
export const useContractorInfoColumns = (setProjectTypeModalOpen, projectTypeSelection) => {
  const contractDuplicationColumns = [
    {
      title: 'key',
      datadataIndex: 'key'
    },
    {
      title: 'ID',
      datadataIndex: 'ID'
    },
    {
      title: 'SN',
      datadataIndex: 'SN'
    },
    {
      title: '카테고리',
      datadataIndex: 'category'
    },
    {
      title: '세부카테고리',
      datadataIndex: 'subcategory'
    }
  ]

  const [isDuplicateCaseNumber, setIsDuplicateCaseNumber] = useState(false) // 사건번호 중복
  const [isDuplicateOpponent, setIsDuplicateOpponent] = useState(false) // 상대방 중복
  const [caseNumberStatus, setCaseNumberStatus] = useState('')

  const handleChange = (key, value) => {
    // const isDuplicateCaseNumber = Object.values(formData).some(
    //   (fieldValue) => fieldValue !== value && key !== 'name'
    // )

    setCaseNumberStatus('error')
    setIsDuplicateOpponent(true)
    setIsDuplicateCaseNumber(true)
  }
  const { departments } = useDepartmentList()
  const { users } = useUserList()
  const { categories } = useCategories()
  const projectTypeOptions = _.filter(categories, item =>
    ['df4a776b-be23-4e57-9522-6e60de3f4a0f', 'e9d60a0d-aa1a-469f-9fde-39fd19700f96'].includes(item.value)
  )
  const projectTypeShowModal = (index) => {
    setProjectTypeModalOpen(true)
  }
  const duplicationTableColumns = [
    {
      header: '의뢰인명',
      title: '의뢰인명',
      dataIndex: 'name',
      key: 'name'
    },
    {
      header: '위임인명',
      title: '위임인명',
      dataIndex: 'representer',
      key: 'representer'
    },
    {
      header: '전화번호',
      title: '전화번호',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      header: '주소',
      title: '주소',
      dataIndex: 'address',
      key: 'address'
    },
    {
      header: '이메일',
      title: '이메일',
      dataIndex: 'email',
      key: 'email'
    },
    {
      header: (
        <>
          주민번호/ <br />
          사업자등록번호
        </>
      ),
      title: '주민번호/사업자등록번호',
      dataIndex: 'registrationNumber',
      key: 'registrationNumber'
    },
    {
      header: '사건번호',
      title: '사건번호',
      dataIndex: 'caseNumber',
      key: 'caseNumber'
    },
    {
      header: '사건명',
      title: '사건명',
      dataIndex: 'caseName',
      key: 'caseName'
    },
    {
      header: '상대방',
      title: '상대방',
      dataIndex: 'opponent',
      key: 'opponent'
    },
    {
      header: '관할',
      title: '관할',
      dataIndex: 'jurisdiction',
      key: 'jurisdiction'
    }
  ]
  const callDataSource = [
    {
      title: '의뢰인명',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        return (
          <Form.Item
            name='name'
          >
            <Input
              placeholder='의뢰인명'
            />
          </Form.Item>
        )
      }
    },
    {
      title: '위임인명',
      dataIndex: 'representer',
      key: 'representer',
      render: (name, record) => {
        return (
          <Form.Item
            name='representer'
          >
            <Input
              placeholder='위임인명'
            />
          </Form.Item>
        )
      }
    },
    {
      title: '전화번호',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone, record) => {
        return (
          <Form.Item
            name='phone'
          >
            <Input
              placeholder='전화번호'
            />
          </Form.Item>
        )
      }
    },
    {
      title: '주소',
      dataIndex: 'address',
      key: 'address',
      render: (address, record) => {
        return (
          <Form.Item
            name='address'
          >
            <Input
              placeholder='주소'
            />
          </Form.Item>
        )
      }
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      render: (email, record) => {
        return (
          <Form.Item
            name='email'
          >
            <Input
              placeholder='이메일'
            />
          </Form.Item>
        )
      }
    },
    {
      title: '주민번호/사업자등록번호',
      dataIndex: 'registrationNumber',
      key: 'registrationNumber',
      render: (registrationNumber, record) => {
        return (
          <Form.Item
            name='registrationNumber'
          >
            <Input
              placeholder='주민번호/사업자등록번호'
            />
          </Form.Item>
        )
      }
    },
    {
      title: '사건번호',
      dataIndex: 'caseNumber',
      key: 'caseNumber',
      render: (caseNumber, record, index) => {
        return (
          <Form.Item
            name='caseNumber'
            key={caseNumber}
            validateStatus={isDuplicateCaseNumber ? 'error' : ''}
            help={isDuplicateCaseNumber ? '중복된 사건번호가 있습니다.' : null}
          >
            <Input
              placeholder='사건번호'
            />
          </Form.Item>
        )
      }
    },
    {
      title: '사건명',
      dataIndex: 'caseName',
      key: 'caseName',
      render: (caseName, record) => {
        return (
          <Form.Item
            name='caseName'
          >
            <Input
              placeholder='사건명'
              defaultValue={caseName || undefined}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '상대방',
      dataIndex: 'opponent',
      key: 'opponent',
      render: (opponent, record) => {
        return (
          <Form.Item
            name='opponent'
            key={opponent}
            validateStatus={isDuplicateOpponent ? 'error' : ''}
            help={isDuplicateOpponent ? '중복된 상대방이 있습니다.' : null}
          >
            <Input
              placeholder='상대방'
              status={caseNumberStatus}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '관할',
      dataIndex: 'jurisdiction',
      key: 'jurisdiction',
      render: (jurisdiction, record) => {
        return (
          <Form.Item
            name='jurisdiction'
          >
            <Input
              placeholder='관할'
            />
          </Form.Item>
        )
      }
    }
  ]
  const caseDataSource = [
    {
      title: '사건번호',
      dataIndex: 'caseNumber',
      key: 'caseNumber',
      render: (caseNumber, record) => {
        return (
          <Form.Item
            name='caseNumber'
          >
            <Input
              placeholder='사건번호'
            />
          </Form.Item>
        )
      }
    },
    {
      title: '사건명',
      dataIndex: 'caseName',
      key: 'caseName',
      render: (caseName, record) => {
        return (
          <Form.Item
            name='caseName'
          >
            <Input
              placeholder='사건명'
            />
          </Form.Item>
        )
      }
    },
    {
      title: '사건구분',
      dataIndex: 'projectType',
      key: 'projectType',
      render: (projectType, record, index) => {
        const name = findMatchingProjectType(projectType.id, projectTypes)
        return (
          <Form.Item
            name={['projectType']}
          >
            {/* <Select
              placeholder='사건구분'
              // defaultValue={projectType || undefined}
              options={projectTypeOptions}
            /> */}
            <StyledButton
              placeholder='사건구분'
              onClick={() => projectTypeShowModal(index)}
              style={{ width: 320, justifyContent: 'left' }}
            >
              {projectType !== null
                ? (
                  <EllipsisContainer>
                    {findMatchingProjectType(projectType.id, projectTypes)}
                  </EllipsisContainer>)
                : (
                  <div style={{ color: '#d3d3d3' }}>사건구분</div>)}
            </StyledButton>
          </Form.Item>
        )
      }
    },
    {
      title: '부서',
      dataIndex: 'department',
      key: 'department',
      width: 220,
      render: (department, record) => {
        return (
          <Form.Item
            name={['department', 'id']}
          >
            <Select
              placeholder='부서'
              options={departments.map(v => ({ label: v.name, value: v.id }))}
              allowClear
            />
          </Form.Item>
        )
      }
    },
    {
      title: '담당변호사',
      dataIndex: 'assignedLawyers',
      key: 'assignedLawyers',
      render: (assignedLawyers, record) => {
        return (
          <Form.Item
            name={['assignedLawyers', 'id']}
          >
            <Select
              placeholder='담당변호사'
              options={users.map(v => ({ label: v.name, value: v.id }))}
            />
          </Form.Item>
        )
      }
    }
  ]
  return {
    callDataSource,
    contractDuplicationColumns,
    isDuplicateCaseNumber,
    isDuplicateOpponent,
    duplicationTableColumns,
    caseDataSource
  }
}
