import { useEffect, useRef, useState } from 'react'
import ContractCustomCalendar from '../common/ContractCustomCalendar'
import { PaymentViewMode } from '../paymentCustomTable/PaymentViewMode'
import { ContractGroupMode } from '../recordCustomTable/ContractGroupMode'
import { MoneyContainer, RowGapWrapper } from '../../../common/Theme/style'
import { HeaderGroup, Spacer } from '../../../common/components/PageHeaderStyles'
import { useContractCalendar } from '../../hooks/contractRecord/useContractCalendar'
import { TotalIndicator } from '../../../timeCharge/components/common/CustomTable/TotalIndicator'

import styled from 'styled-components'
import { Pagination, Switch, Table } from 'antd'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-table-wrapper .ant-table-cell-fix-left, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table-cell-fix-right {
    z-index: 1;
  }
  .ant-table-row {
    cursor: pointer;
  }
`
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0px;
`
const CollectionInfoContainer = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  min-width: 200px;
  gap: 8px;
`
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  padding: 8px 14px;
`
const FilterResultContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`
const Tag = styled.div`
  color: #797979;
  font-size: 14px;
`

export const ContractCustomTable = ({
  data = [],
  defaultColumns = [],
  groupColumns,
  typeGroupColumns,
  total,
  pageType,
  viewMode,
  groupModeContract,
  onClickRow = () => {},
  scroll,
  loading,
  onChangeViewMode = () => {},
  onChangeGroupMode = () => {},
  contractStateSwitch,
  setContractStateSwitch,
  totalDueAmount,
  currentfilteredType1,
  currentfilteredType2,
  pagination = true,
  onChangePage = () => {},
  page,
  limit
}) => {
  const [columns, setColumns] = useState(defaultColumns)
  const [dataToal, setDataTodal] = useState(total)

  const textRef = useRef()
  const isEditing = (record) => null

  const mergedColumns = columns?.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputtype: col.dataIndex === 'id' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        ref: isEditing(record) ? textRef : null
      })
    }
  })

  const {
    totalCount,
    ...calendarProps
  } = useContractCalendar(
    pageType,
    contractStateSwitch
  )

  useEffect(() => {
    if (viewMode === 'calendar') {
      setDataTodal(totalCount)
    } else if (viewMode === 'list') {
      setColumns(defaultColumns)
      setDataTodal(total)
    } else if (groupModeContract === 'DEPARTMENT') {
      setColumns(groupColumns)
      setDataTodal(total)
    } else {
      setDataTodal(total)
      setColumns(typeGroupColumns)
    }
  }, [viewMode, defaultColumns, totalCount])
  return (
    <Container>
      <HeaderGroup>
        {pageType === 'contractRecord' && (
          <FilterContainer>
            <FilterResultContainer>
              <CollectionInfoContainer>
                <Tag>총 미수금</Tag>
                <MoneyContainer value={totalDueAmount !== null ? totalDueAmount : 0} />
              </CollectionInfoContainer>
              <RowGapWrapper $gap={1}>
                <CollectionInfoContainer>
                  <Tag>형사 미수금</Tag>
                  <MoneyContainer value={currentfilteredType1} />
                </CollectionInfoContainer>
                <CollectionInfoContainer>
                  <Tag>민사 미수금</Tag>
                  <MoneyContainer value={currentfilteredType2} />
                </CollectionInfoContainer>
              </RowGapWrapper>
            </FilterResultContainer>
          </FilterContainer>
        )}
        <Spacer />
        {pageType === 'contractRecord' && (
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <Switch
              checked={contractStateSwitch}
              onChange={() => setContractStateSwitch(!contractStateSwitch)}
              checkedChildren='종결보기'
              unCheckedChildren='전체보기'
            />
          </div>)}
        {viewMode === 'group' &&
          (
            <ContractGroupMode
              groupModeContract={groupModeContract}
              onChangeGroupMode={onChangeGroupMode}
            />)}
        {pageType !== 'contractRecordDeleted'
          ? (
            <PaymentViewMode
              viewMode={viewMode}
              onChangeViewMode={onChangeViewMode}
            />)
          : null}
        <TotalIndicator total={dataToal} unit='개' loading={loading} />
      </HeaderGroup>
      {viewMode === 'calendar'
        ? (<ContractCustomCalendar {...calendarProps} />)
        : (
          <>
            <Table
              rowKey='key'
              loading={loading}
              columns={mergedColumns}
              dataSource={data}
              pagination={false}
              pageType={pageType}
              size='middle'
              scroll={{
                x: scroll
              }}
              onRow={(record) => ({
                onClick: () => onClickRow(record, viewMode)
              })}
            />
            {pagination
              ? (
                <PaginationContainer>
                  <Pagination
                    showSizeChanger={false}
                    current={page}
                    total={total}
                    pageSize={limit}
                    onChange={(v) => onChangePage(v)}
                  />
                </PaginationContainer>
                )
              : null}
          </>
          )}
    </Container>
  )
}
