import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'

import { cycleOptions } from '../../config'
import { uuidv4 } from '../../../common/util'
import { CustomDatePicker } from '../../components/common/CustomDatePicker'
import { ColumnGapWrapper, RowGapWrapper } from '../../../common/Theme/style'
import { CardHeader } from '../../components/contractRecordDetail/CardHeader'
import { HeaderGroup, Spacer } from '../../../common/components/PageHeaderStyles'
import { ContractCategoryButton } from '../../components/contractRecordDetail/ContractCategoryButton'
import { calculateDateDuration, calculatePaymentDuration, todayDate } from '../../components/common/CommonFunction'
import { useContractPaymentColumns } from '../../hooks/contractRecord/contractRecordDetail/useContractPaymentColumns'
import { useExtraContractPaymentColumns } from '../../hooks/contractRecord/contractRecordDetail/useExtraContractPaymentColumns.js'

import _ from 'lodash'
import { EditOutlined } from '@ant-design/icons'
import { Button, Form, InputNumber, Modal, Select, Table } from 'antd'
import styled from 'styled-components'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
dayjs.locale('ko')

const StyledSelect = styled(Select)`
  min-width: 100px;
`
const StyledButton = styled(Button)`
  width: 100px;
  padding: 18px 100px;
  margin-top: 10px;
`

// 계약 기록 > 계약 금액 목록
export const ContractContainer = ({ contractData, setContractData, contractType, title, options, readOnly, form, newContractIds, setNewContractIds, addContract, setAddContract, onEdit = () => {}, handleSave = () => {}, onCancel = () => {}, editMode, onDeleteContractPayment, refetch, formEditable }) => {
  const TAGDATAGAP = 10
  const [contractDataSource, setContractDataSource] = useState([])
  const [isModalOpenContractType, setIsModalOpenContractType] = useState(false)
  const [multipleContract, setMultipleContract] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState()
  const handleMultipleContract = (
    count = 1,
    dueAmount = 0,
    dueDate = null,
    periodic = null
  ) => {
    setIsModalOpenContractType(false)
    setAddContract(false)
    const currentFormData = form.getFieldValue(contractType)
    const updatedData = _.cloneDeep(currentFormData)
    const dueAmounts = calculatePaymentDuration(count, dueAmount)
    const dueDates = calculateDateDuration(periodic, count, dueDate)
    const newContract = _.times(count, (index) => {
      const newId = uuidv4()
      const newContractData = {
        key: newId,
        id: newId,
        category: selectedCategory.value,
        dueAmount: dueAmounts[index],
        dueDate: dueDates[index],
        periodic,
        periodicId: newId,
        payments: []
        // date: parseDateObj(new Date())
      }
      return newContractData
    })
    const periodicIds = newContract.map(contract => contract.periodicId)
    setNewContractIds([...newContractIds, ...periodicIds])
    newContract.forEach(contract => {
      _.set(updatedData, [contract.id], contract)
    })
    form.setFieldsValue({ [contractType]: updatedData })

    setContractDataSource(updatedData)
    setContractData(updatedData)
    setSelectedCategory(null)
    setMultipleContract(null)
  }
  const handleAddContract = (
    dueAmount = null,
    dueDate = todayDate,
    periodic = null,
    category = null,
    condition = null
  ) => {
    setIsModalOpenContractType(false)
    const newId = uuidv4()
    const currentFormData = form.getFieldValue(contractType)
    const updatedData = _.cloneDeep(currentFormData)
    const newContract = {
      key: newId,
      id: newId,
      periodicId: newId,
      dueAmount,
      category: category === null ? selectedCategory.value : category.value,
      periodic,
      dueDate,
      condition,
      payments: []
    }

    _.set(updatedData, `${newContract.periodicId}`, newContract)
    form.setFieldsValue({ [contractType]: updatedData })
    setContractDataSource(updatedData)
    setContractData(updatedData)
    setSelectedCategory(null)
    setMultipleContract(null)
    setNewContractIds([...newContractIds, newId])
    setAddContract(false)
  }

  const {
    contractColumns
  } = useContractPaymentColumns(
    form,
    contractType,
    readOnly,
    onDeleteContractPayment,
    editMode,
    handleSave,
    refetch,
    formEditable
  )

  const {
    extraContractColumns
  } = useExtraContractPaymentColumns(
    form,
    contractType,
    readOnly,
    onDeleteContractPayment,
    editMode,
    handleSave,
    refetch,
    formEditable
  )

  const handleCategoryClick = (data) => {
    setAddContract(true)
    setSelectedCategory(data)
    if (contractType === 'contract') {
      setIsModalOpenContractType(true)
    } else {
      handleAddContract(null, null, null, data)
    }
  }
  const { permissions } = useOutletContext()
  const handleOk = () => {
    setIsModalOpenContractType(false)
    setMultipleContract(false)
    setAddContract(false)
    // 주기 계산
    const currentForm = contractForm.getFieldValue() || []
    handleMultipleContract(_.get(currentForm, 'count'), _.get(currentForm, 'dueAmount'), _.get(currentForm, 'dueDate'), _.get(currentForm, 'periodic'))
    setAddContract(false)
    contractForm.resetFields()
  }
  const handleCancel = () => {
    setIsModalOpenContractType(false)
    setMultipleContract(false)
    setAddContract(false)
    contractForm.resetFields()
  }
  const handleAddMultipleContract = () => {
    setMultipleContract(true)
    setAddContract(false)
  }
  const SubmitButton = ({ form, children, onClick }) => {
    const [submittable, setSubmittable] = useState(false)
    const values = Form.useWatch([], form)
    useEffect(() => {
      form
        .validateFields({ validateOnly: true })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false))
    }, [form, values])
    return (
      <Button
        type='primary'
        htmlType='submit'
        disabled={!submittable}
        onClick={onClick}
      >
        {children}
      </Button>
    )
  }
  const [contractForm] = Form.useForm()
  return (
    <Form
      form={form}
    >
      <Modal
        width={1000}
        open={isModalOpenContractType}
        footer={multipleContract && [
          <Button key='back' onClick={handleCancel}>
            취소하기
          </Button>,
          <SubmitButton key='submit' type='primary' form={contractForm} onClick={handleOk}>
            생성하기
          </SubmitButton>
        ]}
        onCancel={handleCancel}
        okText='저장'
        cancelText='취소'
      >
        <RowGapWrapper $gap={10} style={{ justifyContent: 'center' }}>
          {addContract && (
            <RowGapWrapper $gap={10} style={{ justifyContent: 'center' }}>
              <StyledButton onClick={() => handleAddContract()}>단일 입금</StyledButton>
              <StyledButton onClick={() => handleAddMultipleContract()}>주기 입금</StyledButton>
            </RowGapWrapper>
          )}
          {/* <Button onClick={() => { console.log('contract', contractForm.getFieldValue()) }}>DEBUG!</Button> */}

          {multipleContract && (
            <Form
              form={contractForm}
            >
              <RowGapWrapper $gap={20} style={{ margin: '100px 10px' }}>
                <RowGapWrapper $gap={TAGDATAGAP} style={{ alignItems: 'center' }}>
                  <Form.Item
                    name='dueAmount'
                    rules={[
                      { required: true, message: '금액을 입력해주세요.' },
                      {
                        validator: (_, value) =>
                          value > 0
                            ? Promise.resolve()
                            : Promise.reject(new Error('0보다 큰 숫자를 입력해주세요.'))
                      }
                    ]}
                    label='총 금액'
                  >
                    <InputNumber
                      changeOnWheel
                      placeholder='0'
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      addonAfter='원'
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                      min={0}
                      style={{
                        width: 160
                      }}
                    />
                  </Form.Item>
                </RowGapWrapper>
                <RowGapWrapper $gap={TAGDATAGAP} style={{ alignItems: 'center' }}>
                  <Form.Item
                    name='count'
                    rules={[{ required: true, message: '차수를 입력해주세요.' },
                      {
                        validator: (_, value) =>
                          value > 0
                            ? Promise.resolve()
                            : Promise.reject(new Error('0보다 큰 숫자를 입력해주세요.'))
                      }
                    ]}
                    label='차수'
                  >
                    <InputNumber
                      min={0}
                      max={400}
                      style={{ width: '130px' }}
                    />
                  </Form.Item>
                </RowGapWrapper>
                <RowGapWrapper $gap={TAGDATAGAP} style={{ alignItems: 'center' }}>
                  <RowGapWrapper>
                    <Form.Item
                      name='periodic'
                      rules={[{ required: true, message: '주기를 입력해주세요.' }]}
                      label='주기'
                    >
                      <StyledSelect
                        placeholder='주기'
                        style={{ minWidth: '55px' }}
                        options={cycleOptions}
                      />
                    </Form.Item>
                    <Form.Item
                      name='dueDate'
                      rules={[{ required: true, message: '날짜를 선택해주세요.' }]}
                    >
                      <CustomDatePicker
                        onChange={(date) => {
                          const newDateTime = date ? date.format('YYYY-MM-DD') : null
                          contractForm.setFieldValue(['dueDate'], newDateTime)
                        }}
                        placeholder='날짜 선택'
                      />
                    </Form.Item>
                  </RowGapWrapper>
                </RowGapWrapper>
              </RowGapWrapper>
            </Form>
          )}
        </RowGapWrapper>
      </Modal>
      <RowGapWrapper $gap={20}>
        <CardHeader type='button' dataIndex='contractList' title={title} value='추가' readOnly={readOnly} />
        {!readOnly && (
          <ContractCategoryButton
            categories={options}
            contractData={contractData}
            setContractData={setContractData}
            handleCategoryClick={handleCategoryClick}
            setIsModalOpenContractType={setIsModalOpenContractType}
            setAddContract={setAddContract}
          />
        )}
        <Spacer />
        {formEditable
          ? (readOnly
              ? (
                <HeaderGroup>
                  {(_.includes(permissions, 'UPDATE_PROJECT_DATA')) && ( // 수정: permissions
                    <>
                      <Button
                        variant='outlined'
                        icon={<EditOutlined />}
                        onClick={() => onEdit()}
                        disabled={editMode}
                      >
                        수정하기
                      </Button>
                    </>
                  )}
                </HeaderGroup>)
              : (
                <HeaderGroup>
                  {_.includes(permissions, 'UPDATE_PROJECT_DATA') && (
                    <>
                      <Button onClick={() => onCancel(form, contractType)}>
                        취소하기
                      </Button>
                      <Button
                        htmlType='submit' type='primary' form={form}
                        onClick={async () => {
                          try {
                            await form.validateFields()
                            handleSave(contractType)
                          } catch (errorInfo) {
                            console.error('Validation Failed:', errorInfo)
                          }
                        }}
                      >
                        저장하기
                      </Button>
                    </>)}
                </HeaderGroup>))
          : null}
      </RowGapWrapper>
      <ColumnGapWrapper $gap={10} style={{ marginTop: 10 }}>
        {_.map(options, (option) => {
          const currentData = _.filter(contractData, { category: option.value })
          if (currentData.length > 0) {
            return (
              <Table
                columns={contractType !== 'extraContract' ? contractColumns : extraContractColumns}
                dataSource={_.filter(contractData, { category: option.value })}
                pagination={false}
                size='small'
              />
            )
          }
        })}
      </ColumnGapWrapper>
    </Form>
  )
}
