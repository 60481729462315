import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { paymentCategoryOptions } from '../../config'
import { ContractContainer } from './ContractContainer'
import { GrayCustomCard } from '../../../common/Theme/style'
import { useConfirmModal } from '../../hooks/common/useConfirmModal'
import { ConfirmModal } from '../../../common/components/ConfirmModal'
import { getRecordFormStatus } from '../../cacheAndNavigation/contractRecordDetail/cache'
import { navigateToContractRecordDetail } from '../../cacheAndNavigation/contractRecordDetail/navigation'
import { useContractRecordAddEditRemove } from '../../hooks/contractRecord/useContractRecordAddEditRemove'
import { useSavingContractRecordDetailForm } from '../../hooks/contractRecord/contractRecordDetail/useSavingContractRecordDetailForm'

import { Form } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
dayjs.locale('ko')

export const ContractForm = ({ id, editMode, setEditMode, contractRecordData, refetch, form, formEditable }) => {
  const [pageDelete, setPageDelete] = useState(false)
  const [contractDataSource, setContractDataSource] = useState([]) // 추가 계약 목록
  const [newContractIds, setNewContractIds] = useState([])
  const [deletedContractIds, setDeletedContractIds] = useState([])
  const [newReasonIds, setNewReasonIds] = useState([])
  const [removedReasonIds, setRemovedReasonIds] = useState([])
  const [newDepositIds, setNewDepositIds] = useState([])
  const [removedDepositIds, setRemovedDepositIds] = useState([])
  const [addContract, setAddContract] = useState(false)
  const navigate = useNavigate()

  const formStatus = getRecordFormStatus()
  const prevStatus = useRef(formStatus)

  useEffect(() => {
    const currentFormValues = form.getFieldValue()
    setContractDataSource(currentFormValues.contract)
  }, [contractRecordData, contractDataSource])


  const {
    cancelModalOpen,
    onEdit,
    onCancel,
    onSave,
    onDeleteContractPayment,
    readOnly
  } = useSavingContractRecordDetailForm(
    contractRecordData,
    formStatus,
    newContractIds,
    deletedContractIds,
    refetch,
    setNewContractIds,
    setDeletedContractIds,
    newReasonIds,
    setNewReasonIds,
    removedReasonIds,
    setRemovedReasonIds,
    newDepositIds,
    setNewDepositIds,
    removedDepositIds,
    setRemovedDepositIds,
    pageDelete,
    setEditMode,
    setContractDataSource,
    contractDataSource
  )
  const {
    onRemoveContractRecord
  } = useContractRecordAddEditRemove(
    refetch
  )

  const {
    title: confirmModalTitle,
    confirmModalMeta,
    onCloseModal
  } = useConfirmModal(
    null,
    onRemoveContractRecord
    // onDeleteCallRecord,
    // onDeleteContract,
    // onDeleteContractItem,
    // onDeleteComment,
    // onDeletePaymentRecord
  )

  const onOkDeleteConfirmModal = () => {
    setPageDelete(true)
    onRemoveContractRecord(id)
  }

  const handleSave = (type) => {
    onSave(form, contractRecordData.id, type)
    navigateToContractRecordDetail(navigate, contractRecordData, null, 'view')
  }

  useEffect(() => {
    if (!cancelModalOpen && !pageDelete) {
      prevStatus.current = formStatus
    }
  }, [cancelModalOpen, pageDelete])

  const onFinish = (values) => {
    form.resetFields()
  }

  return (
    <>
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        onOk={onOkDeleteConfirmModal}
        onCancel={onCloseModal}
      />
      <Form
        form={form}
        onFinish={onFinish}
      >
        {/* <Button onClick={() => { console.log('contract', form.getFieldValue()) }}>DEBUG!</Button> */}
        <GrayCustomCard $readOnly={readOnly}>
          <ContractContainer
            contractType='contract'
            title='계약 금액'
            id={id}
            contractData={contractDataSource}
            setContractData={setContractDataSource}
            options={paymentCategoryOptions}
            readOnly={readOnly}
            form={form}
            newContractIds={newContractIds}
            setNewContractIds={setNewContractIds}
            addContract={addContract}
            setAddContract={setAddContract}
            onEdit={onEdit}
            handleSave={handleSave}
            onCancel={onCancel}
            editMode={editMode}
            onDeleteContractPayment={onDeleteContractPayment}
            refetch={refetch}
            formEditable={formEditable}
          />
        </GrayCustomCard>
      </Form>
    </>
  )
}
