import _ from 'lodash'
import { getPaymentContractRecordList } from '../../../apollo/contractRecordQueries'
import { useQuery } from '@apollo/client'
import { useCustomTable } from '../../../../timeCharge/hooks/common/useCustomTable'
import { useEffect, useRef } from 'react'
import { parseToInt } from '../../../components/common/CommonFunction'

export const usePaymentRecordList = (
  ascending = false,
  key = null,
  searchQuery,
  from,
  to,
  departmentId = null,
  deleted = null,
  caseNumber = null,
  projectTypeId = null,
  assignedLawyerId = null,
  groupModeContract = null,
  outstanding,
  outstandingFrom = null,
  outstandingTo = null,
  closed = null,
  projectTypeStr = null
) => {
  const preAllocationTableProps = useCustomTable()
  const {
    page,
    limit,
    onChangePage
  } = preAllocationTableProps
  const filter = {
    searchQuery,
    closed,
    ...from && to
      ? {
          dateRange: {
            from,
            to
          }
        }
      : {},
    assignedLawyerId,
    departmentId,
    deleted,
    caseNumber,
    projectTypeId,
    projectTypeStr,
    outstanding,
    dueDateRange: {
      from: outstandingFrom,
      to: outstandingTo
    }
  }
  // page handling
  const prevFilter = useRef(filter)
  useEffect(() => {
    if (!_.isEqual(prevFilter.current, filter)) {
      onChangePage(1)
      prevFilter.current = filter
    }
  }, [filter])

  const variables = {
    sortBy: {
      ascending,
      key
    },
    filter: {
      searchQuery,
      closed,
      ...from && to
        ? {
            dateRange: {
              from,
              to
            }
          }
        : {},
      assignedLawyerId,
      departmentId,
      deleted,
      caseNumber,
      projectTypeId,
      projectTypeStr,
      outstanding,
      dueDateRange: {
        from: outstandingFrom,
        to: outstandingTo
      }
    },
    groupBy: groupModeContract,
    offset: (page - 1) * limit,
    limit
  }
  const { data } = useQuery(getPaymentContractRecordList, {
    variables,
    fetchPolicy: 'no-cache'
  })

  return {
    totalDueAmount: parseToInt(_.get(data, ['GetContractRecordList', 'totalDueAmount'], 0)) - parseToInt(_.get(data, ['GetContractRecordList', 'totalReceived'], 0)),
  }
}
